import { TooltipOption } from "echarts/types/dist/shared";
import { EvaChartStates } from "types/factory";

import { formatBarTooltip } from "./utils";

export function translateStackedBarResponseTooltipOptions(
  states: EvaChartStates
): TooltipOption {
  const { layout, histogramToggle } = states;
  const yAxisTitle = layout?.yAxis?.title || "";
  return {
    trigger: "axis",
    confine: true,
    enterable: true,
    extraCssText: "border-style:none;",
    className: "chart-tooltip-container",
    showDelay: 500,
    hideDelay: 0,
    show: true,
    axisPointer: {
      label: {
        precision: 2,
        show: true
      },
      type: "cross"
    },

    position: function (point, _, dom) {
      const xPos = point[0];
      const yPos =
        point[1] - (dom as HTMLDivElement).offsetHeight > 48
          ? point[1] - (dom as HTMLDivElement).offsetHeight
          : 48;
      return [xPos, yPos]; //position top right of mouse cursor
    },
    formatter: (items) => {
      if (items && items.length > 0) {
        return formatBarTooltip(items, yAxisTitle, histogramToggle?.active);
      }
      return "";
    }
  };
}

import {
  DEFAULT_AXIS_LINE_COLOUR,
  DEFAULT_AXIS_SPLIT_NUMBER,
  DEFAULT_DOT_OPACITY,
  DEFAULT_FONT_WEIGHT,
  DEFAULT_POINT_SIZE
} from "constants/chart.constants";
import { EvaChart } from "constants/charts.enums";
import { createEntityState } from "entities/charts/factory";
import { ChartOption } from "entities/charts/options";
import _sortBy from "lodash/sortBy";
import _zip from "lodash/zip";
import { AxisType, SeriesType } from "types/echarts";
import { EvaChartStates } from "types/factory";
import { with2Decimals } from "utils";
import { removeProbitInfoFromtitle } from "utils/removeProbitInfoFromTitle";

import { DataEnum } from "models";

import { createChartLocks } from "../locks";
import { BLUR_OPACITY, DEFAULT_WIDTH, HIGHLIGHTED_WIDTH } from "./constants";

export function translateProbitResponseToOptions(states: Partial<EvaChartStates>) {
  const {
    title,
    series,
    uwis,
    layout,
    uwiCoordinates,
    pointOpacity,
    pointSize,
    hoverLegendItem,
    hoverLegendGroup,
    attentionWells,
    selectedGroups
  } = states;

  const { lockedXMax, lockedXMin, lockedYMax, lockedYMin } = createChartLocks({
    type: EvaChart.Probit,
    states
  });
  const pointOpacityValue = pointOpacity?.properties?.value ?? DEFAULT_DOT_OPACITY;
  const pointSizeValue = pointSize?.properties?.value ?? DEFAULT_POINT_SIZE;
  const yAxisTitle = layout?.yAxis?.title || "";
  const xMin = lockedXMin ?? layout?.xAxis?.min;
  const xMax = lockedXMax ?? layout?.xAxis?.max;
  const yMin = lockedYMin ?? layout?.yAxis?.min;
  const yMax = lockedYMax ?? layout?.yAxis?.max;

  const isHoveringOnBins = hoverLegendItem && attentionWells?.length === 0;
  const isHoveringOnChart =
    hoverLegendItem && hoverLegendGroup && hoverLegendGroup !== hoverLegendItem;
  const isHoveringOnMap = hoverLegendItem && attentionWells?.length > 0;
  const labels = {};
  for (const kvp of layout.yAxis.labels) {
    labels[kvp.value] = kvp.label;
  }

  const options = createEntityState(ChartOption.Chart, {
    chartType: EvaChart.Probit,
    title: {
      ...createEntityState(ChartOption.Title, {
        text: title.toUpperCase()
      })
    },
    yAxis: {
      ...createEntityState(ChartOption.YAxis, {
        name: yAxisTitle,
        nameGap: 45,
        nameTruncate: { maxWidth: 520.2, ellipsis: "..." },
        min: yMin,
        max: yMax,
        type: "value",
        nameLocation: "middle",
        yAxisScaleToX: layout.yAxis.yScaleToX,
        axisTick: {
          alignWithLabel: true,
          customValues: _sortBy(Object.keys(labels).map((v) => parseFloat(v)))
        },

        axisLabel: {
          customValues: _sortBy(Object.keys(labels).map((v) => parseFloat(v))),
          labels: labels,
          formatter: createProbitAxisLabelFormatter(labels),
          fontWeight: DEFAULT_FONT_WEIGHT,
          color: "#5B6770",
          fontSize: 13
        },
        axisLine: {
          onZero: false,
          lineStyle: { color: "rgb(155,155,155)", width: 1 }
        },
        splitLine: {
          lineStyle: {
            color: "#D9D9D9"
          }
        },
        lock: {
          min: { value: yMin, type: DataEnum.Number },
          max: { value: yMax, type: DataEnum.Number },
          show: true
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)
    },
    xAxis: {
      ...createEntityState(ChartOption.XAxis, {
        name: layout.xAxis.title,
        nameGap: 27,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type: AxisType.Log as any,
        min: xMin,
        max: xMax,
        minorTick: {
          show: true,
          splitNumber: DEFAULT_AXIS_SPLIT_NUMBER
        },
        minorSplitLine: {
          show: true,
          lineStyle: { color: DEFAULT_AXIS_LINE_COLOUR }
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore

        lock: {
          show: true,
          min: { value: xMin, type: DataEnum.Number },
          max: { value: xMax, type: DataEnum.Number }
        },
        useY: true
      })
    },
    grid: { top: 46, bottom: 46, left: 62, right: 20 },
    uwiList: uwis,
    uwiCoordinates: uwiCoordinates,
    series: series.map((s, index) => {
      const { label, style, x, y, uwiIndices } = s;
      const zippedData = _zip(x, y, uwiIndices);
      if (zippedData.length === 0) {
        return null;
      }
      const type = s.mode === "markers" ? "scatter" : s.mode;
      const seriesName = removeProbitInfoFromtitle(label);
      const hoverItem = removeProbitInfoFromtitle(hoverLegendItem);

      const isHoveringLine = attentionWells?.length === 0 && hoverItem === seriesName;
      const { dotOpacity, lineOpacity, lineWidth } = getHoverOptions(
        isHoveringOnBins,
        isHoveringOnChart,
        isHoveringOnMap,
        hoverItem,
        selectedGroups,
        seriesName,
        pointOpacityValue
      );
      if (type === SeriesType.Scatter) {
        return {
          ...createEntityState(ChartOption.ScatterSeries, {
            id: `${label}, ${index}`,
            name: label,
            data: zippedData,
            itemStyle: {
              color: s.isForecast ? "none" : style.hexColor,
              borderColor: s.isForecast ? style.hexColor : "none",
              opacity: dotOpacity
            },
            large: true,
            blendMode: "source-over",
            symbolSize: pointSizeValue,
            emphasis: null
          })
        };
      } else if (SeriesType.Line) {
        return {
          ...createEntityState(ChartOption.LineSeries, {
            id: `${label}, ${index}`,
            name: label,
            data: zippedData,
            itemStyle: { color: style?.hexColor ?? "#000" },
            lineStyle: {
              width: lineWidth,
              color: style?.hexColor,
              opacity: lineOpacity
            },
            z: isHoveringLine ? 3 : 2
          })
        };
      }
    }),
    hoverLayerThreshold: Infinity
  });

  return options;
}

export function createProbitAxisLabelFormatter(labels: Record<string, string>) {
  return (val: number) => {
    const key = parseFloat(`${with2Decimals(val.toFixed(5), 4)}`);
    if (key in labels) {
      return labels[key];
    }
    return "";
  };
}

function getHoverOptions(
  isHoveringOnBins: boolean,
  isHoveringOnChart: boolean,
  isHoveringOnMap: boolean,
  hoverLegendItem: string,
  selectedGroups: string[],
  seriesName: string,
  pointOpacityValue: number
): { dotOpacity: number; lineOpacity: number; lineWidth: number } {
  let dotOpacity = pointOpacityValue;
  let lineOpacity = 1;
  let lineWidth = DEFAULT_WIDTH;

  const isHighlighted =
    seriesName === hoverLegendItem || (selectedGroups?.includes?.(seriesName) ?? false);
  if (
    isHoveringOnBins ||
    isHoveringOnMap ||
    isHoveringOnChart ||
    selectedGroups.length > 0
  ) {
    dotOpacity = BLUR_OPACITY;
    lineOpacity = BLUR_OPACITY;
    if (isHighlighted) {
      lineOpacity = 1;
      lineWidth = HIGHLIGHTED_WIDTH;
      dotOpacity = isHoveringOnChart || isHoveringOnMap ? BLUR_OPACITY : 1;
    }
  }

  return {
    dotOpacity,
    lineOpacity,
    lineWidth
  };
}

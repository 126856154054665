import { EvaChart } from "constants/charts.enums";
import { EvaChartStates } from "types/factory";

import { cagrLocks } from "./cagr";
import { crossPlotLocks } from "./crossPlot";
import { cumTimeLocks } from "./cumTime";
import { declineRateLocks } from "./declineRate";
import { probitLocks } from "./probit";
import { rateCumLocks } from "./rateCum";
import { rateDateLocks } from "./rateDate";
import { rateTimeLocks } from "./rateTime";
import { stackedBarLocks } from "./stackedBar";
import { totalRateCumLocks } from "./totalRateCum";

export const LOCKS_MAP = {
  [EvaChart.RateCum]: rateCumLocks,
  [EvaChart.RateTime]: rateTimeLocks,
  [EvaChart.CumTime]: cumTimeLocks,
  [EvaChart.RateDate]: rateDateLocks,
  [EvaChart.TotalRateCum]: totalRateCumLocks,
  [EvaChart.CrossPlot]: crossPlotLocks,
  [EvaChart.DeclineRate]: declineRateLocks,
  [EvaChart.CAGR]: cagrLocks,
  [EvaChart.StackedBar]: stackedBarLocks,
  [EvaChart.Probit]: probitLocks
};

export function createChartLocks({
  type,
  states
}: {
  type: EvaChart;
  states: Partial<EvaChartStates>;
}): { lockedXMin: number; lockedXMax: number; lockedYMin: number; lockedYMax: number } {
  const locks = LOCKS_MAP[type];
  if (!locks) {
    throw new Error(`Unknown chart type: ${type} to create locks`);
  }
  return locks(states);
}

import { useEffect } from "react";

import { EvaChart } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import { translateLegendsToParams } from "entities/charts";
import { SeriesType } from "types/echarts";
import { allTrue } from "utils/fp";

import { useChartDependencies } from "../useChartDependencies";
import { useChartEntities } from "../useChartEntities";
import {
  resetScatterHighlight,
  updatePieSeries,
  updateTargetDataEmphasis
} from "./utils";

/*
 * The hook is designed to handle edge cases in chart highlighting, such as rendering issues like black dots and others
 */
export function useChartHighlights(chartInstanceRef: React.RefObject<echarts.ECharts>) {
  const { id, entityKind } = useChartsContext();
  const { chartType, forecast, apiResponse, sum } = useChartEntities(id);
  const spaghetti = sum?.active;
  const isForecastActive = forecast?.active;
  const {
    hoverLegendItem,
    hoverLegendGroup,
    hoverLegendId,
    attentionWells,
    selectedGroups
  } = useChartDependencies(entityKind);

  useEffect(() => {
    if (!chartInstanceRef.current) return;

    if (hoverLegendItem || selectedGroups.length > 0) {
      const chartOptions = chartInstanceRef.current.getOption();
      const seriesArray = Array.isArray(chartOptions?.series) ? chartOptions.series : [];
      if (seriesArray.length === 0) return;
      // TODO chart: use webworker to compute selection index faster
      const selectionIndex = translateLegendsToParams({
        type: chartType ?? EvaChart.None,
        states: {
          hoverLegendItem,
          hoverLegendGroup,
          hoverLegendId,
          attentionWells,
          selectedGroups,
          ...apiResponse
        },
        forecast: isForecastActive,
        series: seriesArray
      });
      if (!selectionIndex) return;

      const { targetIndex, seriesIndex, seriesType } = selectionIndex;

      if (seriesType === SeriesType.Pie) {
        updatePieSeries(
          seriesArray,
          hoverLegendItem,
          hoverLegendGroup,
          selectedGroups,
          chartInstanceRef
        );
      }

      if (seriesType === SeriesType.Bar) {
        return;
      }

      if (seriesType === SeriesType.Scatter) {
        updateTargetDataEmphasis(chartInstanceRef.current, seriesIndex, targetIndex);
        // Used for popping up tooltip when hovering well on map, tooltip can be cut off by the chart bounds so needs
        // further tooltip position modification to show properly, documented in EVA 4404
        //   chartInstanceRef.current.dispatchAction({
        //     type: ChartActionType.ShowTip,
        //     seriesIndex: seriesIndex,
        //     dataIndex: targetIndex
        //   });
      }
    }
    const isHoveringOnBins = allTrue(
      hoverLegendItem?.length > 0,
      !hoverLegendGroup,
      attentionWells?.length === 0
    );
    if (isHoveringOnBins || !hoverLegendItem) {
      resetScatterHighlight(chartInstanceRef.current);
    }
  }, [
    hoverLegendItem,
    hoverLegendGroup,
    hoverLegendId,
    attentionWells,
    selectedGroups,
    chartType,
    apiResponse,
    forecast,
    spaghetti,
    chartInstanceRef.current
  ]);
}

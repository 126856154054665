import { ALL_PRODUCT_TYPES } from "constants/chart.constants";

export const getYMinProductLock = (product: string) => {
  const productLocks = {
    [ALL_PRODUCT_TYPES.Shrinkage.label]: 0,
    [ALL_PRODUCT_TYPES.CWB.label]: -100,
    [ALL_PRODUCT_TYPES.IWB.label]: -100
  };

  return productLocks[product] ?? undefined;
};

import { EvaChart } from "constants/charts.enums";
import { XAXisOption, YAXisOption } from "echarts/types/dist/shared";
import { createEntityState } from "entities/charts/factory";
import { ChartOption } from "entities/charts/options";
import _zip from "lodash/zip";
import { EvaChartStates } from "types/factory";

import { createChartLocks } from "../locks";
import {
  BLUR_OPACITY,
  DEFAULT_WIDTH,
  DEFAULT_Z_INDEX,
  HIGHLIGHTED_WIDTH,
  HIGHLIGHTED_Z_INDEX,
  SELECTED_WELL_COLOR,
  SELECTED_WELL_Z_INDEX
} from "./constants";
import { updateOptionsWithTypeWellsSeries } from "./updateOptionsWithTypeWellSeries";
import {
  computeSelectionStates,
  getDefaultYAxisLogOptions,
  getLineSeriesMinMax
} from "./utils";

export function translateDeclineRateResponseToOptions(states: Partial<EvaChartStates>) {
  const {
    title,
    layout,
    series,
    typeWellSeries = [],
    hoverLegendItem,
    hoverLegendGroup,
    attentionWells = [],
    selectedGroups = [],
    logAxis
  } = states;

  const isOpaque = !(hoverLegendItem || selectedGroups.length > 0);

  const { yMin, yMax } = getLineSeriesMinMax(series);
  const { lockedYMax, lockedYMin } = createChartLocks({
    type: EvaChart.DeclineRate,
    states
  });

  const xAxis: Partial<XAXisOption> = {
    type: "time",
    min: "2000", //TODO chart: hard coded min and max values
    max: "2050",
    name: layout.xAxis.title,
    nameGap: 27
  };

  const yAxis: Partial<YAXisOption> = {
    name: layout?.yAxis?.title || "",
    nameGap: 35,
    nameTruncate: { maxWidth: 520.2, ellipsis: "..." },
    min: lockedYMin,
    max: lockedYMax
  };

  if (logAxis?.properties?.y?.active) {
    const yAxisLogOptions = getDefaultYAxisLogOptions(layout, lockedYMin, lockedYMax);
    Object.assign(yAxis, yAxisLogOptions);
  }

  let options = createEntityState(ChartOption.Chart, {
    chartType: EvaChart.DeclineRate,
    title: {
      ...createEntityState(ChartOption.Title, {
        text: title.toUpperCase()
      })
    },
    yAxis: {
      ...createEntityState(ChartOption.YAxis, {
        ...yAxis,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        lock: {
          show: true, // it manages if lock should be shown or not
          min: {
            value: Math.floor(yMin.value),
            type: yMin.type
          },
          max: {
            value: Math.floor(yMax.value),
            type: yMax.type,
            override: true // it manages if lock should be overridden or not
          }
        }
      })
    },
    xAxis: {
      ...createEntityState(ChartOption.XAxis, {
        ...xAxis,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        useY: false // useY is managed at hd chart
      })
    },
    grid: { top: 46, bottom: 46, left: 62, right: 20 },
    series: series.map((location, index) => {
      const { label, style, x, y, groupTitle } = location;
      const data = _zip(x, y);
      const id =
        groupTitle !== label
          ? `${groupTitle}, ${label}, ${index}`
          : `${groupTitle}, ${index}`;

      const { isSelectedWell, isHovered } = computeSelectionStates({
        hoverLegendItem,
        hoverLegendGroup,
        attentionWells,
        label,
        groupTitle,
        selectedGroups
      });

      const lineSeries = createEntityState(ChartOption.LineSeries, {
        id,
        name: label,
        data: data,
        itemStyle: { color: style?.hexColor ?? "#000" },
        lineStyle: {
          width: isHovered ? HIGHLIGHTED_WIDTH : DEFAULT_WIDTH,
          color: isSelectedWell ? SELECTED_WELL_COLOR : style?.hexColor,
          type: location.isForecast ? "dashed" : "solid",
          opacity: isOpaque ? 1 : isHovered ? 1 : BLUR_OPACITY
        },
        z: isSelectedWell
          ? SELECTED_WELL_Z_INDEX
          : isHovered
          ? HIGHLIGHTED_Z_INDEX
          : DEFAULT_Z_INDEX,
        showSymbol: data.length > 1 ? false : true
      });

      return lineSeries;
    })
  });
  if (typeWellSeries.length > 0) {
    options = updateOptionsWithTypeWellsSeries(options, typeWellSeries);
  }
  if (series.length === 0) {
    options = {};
  }
  return options;
}

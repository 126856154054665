import { DEFAULT_DOT_OPACITY, DEFAULT_POINT_SIZE } from "constants/chart.constants";
import { EvaChart } from "constants/charts.enums";
import { YAXisOption } from "echarts/types/dist/shared";
import { createEntityState } from "entities/charts/factory";
import {
  ChartOption,
  DEFAULT_X_AXIS_OPTIONS,
  DEFAULT_Y_AXIS_OPTIONS
} from "entities/charts/options";
import _zip from "lodash/zip";
import { AxisType } from "types/echarts";
import { EvaChartStates } from "types/factory";

import { DataEnum } from "models";

import { createChartLocks } from "../locks";
import { DEFAULT_Z_INDEX, HIGHLIGHTED_Z_INDEX } from "./constants";
import { computeScatterSelectionStates, getDefaultYAxisLogOptions } from "./utils";

export function translateCrossPlotResponseToOptions(states: Partial<EvaChartStates>) {
  const {
    title,
    series,
    uwis,
    layout,
    uwiCoordinates,
    pointOpacity,
    pointSize,
    chartTypeParam,
    hoverLegendItem,
    selectedGroups = [],
    logAxis
  } = states;

  const isOpaque = !(hoverLegendItem || selectedGroups.length > 0);
  const pointOpacityValue = pointOpacity?.properties?.value ?? DEFAULT_DOT_OPACITY;
  const pointSizeValue = pointSize?.properties?.value ?? DEFAULT_POINT_SIZE;

  const { lockedYMax, lockedYMin, lockedXMax, lockedXMin } = createChartLocks({
    type: EvaChart.CrossPlot,
    states
  });

  const yAxis: Partial<YAXisOption> = {
    name: layout.yAxis.title,
    nameTruncate: { maxWidth: 520, ellipsis: "..." } // TODO chart: use getAxisTitleTruncatingWidth
  };

  const xDataType = chartTypeParam.properties?.x.dataType;
  const yDataType = chartTypeParam.properties?.y.dataType;
  if (logAxis?.properties?.y?.active) {
    const logSettings = getDefaultYAxisLogOptions(layout, lockedYMin, lockedYMax);
    Object.assign(yAxis, logSettings);
    // chart specific settings
    yAxis.min = 0.01;
  } else if (yDataType === DataEnum.Date) {
    yAxis.type = "time";
  } else if (yDataType === DataEnum.Number || yDataType === DataEnum.Integer) {
    yAxis.min = lockedYMin;
    yAxis.max = lockedYMax;
    if (chartTypeParam.properties.y.property.startsWith("Dates")) {
      yAxis.min = lockedYMin || Math.floor(layout.yAxis.min); // TODO chart: need to use user axis locks here (see Chart.tsx line 1319)
      yAxis.max = lockedYMax || Math.ceil(layout.yAxis.max); // TODO chart: need to use user axis locks here (see Chart.tsx line 1320)
    }
  } else {
    yAxis.type = "value";
  }

  yAxis.axisLabel = {
    ...DEFAULT_Y_AXIS_OPTIONS.axisLabel,
    ...(yDataType === DataEnum.Integer ? { formatter: (value) => value.toString() } : {})
  };

  const xAxis = {
    name: layout.xAxis.title,
    ...(xDataType === DataEnum.Date
      ? {
          type: AxisType.Time,
          min: lockedXMin ?? Math.floor(layout.xAxis.min) // TODO chart: verify Chart.tsx line 1083
        }
      : xDataType === DataEnum.Number || xDataType === DataEnum.Integer
      ? {
          min: lockedXMin ?? Math.floor(layout.xAxis.min), // TODO chart: verify Chart.tsx line 1086
          max: lockedXMax ?? Math.ceil(layout.xAxis.max) // TODO chart: verify Chart.tsx line 1087
        }
      : {
          type: AxisType.Category,
          ...(layout.xAxis.data.length > 0 && { data: layout.xAxis.data }) // Only include 'data' if it has a length
        }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    lock: {
      show: xDataType !== DataEnum.Text, // it manages if lock should be shown or not
      min: { value: Math.floor(layout.xAxis.min), type: xDataType },
      max: { value: Math.ceil(layout.xAxis.max), type: xDataType }
    }
  };

  xAxis["axisLabel"] = {
    ...DEFAULT_X_AXIS_OPTIONS.axisLabel,
    ...(xDataType === DataEnum.Integer ? { formatter: (value) => value.toString() } : {})
  };

  const colorList = {};
  for (const s of series) {
    colorList[s.label] = s.style.hexColor;
  }

  let options = createEntityState(ChartOption.Chart, {
    title: {
      ...createEntityState(ChartOption.Title, {
        text: title.toUpperCase()
      })
    },
    xAxis: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ...createEntityState(ChartOption.XAxis, xAxis)
    },
    yAxis: {
      ...createEntityState(ChartOption.YAxis, {
        ...yAxis,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        lock: {
          show: yDataType !== DataEnum.Text, // it manages if lock should be shown or not
          min: { value: Math.floor(layout.yAxis.min), type: yDataType },
          max: { value: Math.ceil(layout.yAxis.max), type: yDataType }
        }
      })
    },
    uwiList: uwis,
    uwiCoordinates: uwiCoordinates,
    series: series.map((s, index) => {
      const { label, style, x, y, uwiIndices } = s;
      const zippedData = _zip(x, y, uwiIndices);

      const { isHovered } = computeScatterSelectionStates({
        hoverLegendItem,
        label,
        selectedGroups
      });

      const scatterSeries = createEntityState(ChartOption.ScatterSeries, {
        id: `${label}, ${index}`,
        name: label,
        data: zippedData,
        itemStyle: {
          color: s.isForecast ? "none" : style.hexColor,
          borderColor: s.isForecast ? style.hexColor : "none",
          opacity: isOpaque ? pointOpacityValue : isHovered ? pointOpacityValue : 0.2
        },
        symbolSize: pointSizeValue,
        z: isHovered ? HIGHLIGHTED_Z_INDEX : DEFAULT_Z_INDEX
      });
      return scatterSeries;
    }),
    /* A large dataset may require that we stop hovered elements from being rendered in a separate layer,
     * to avoid visual issues of other elements rendering (when hovering).
     * It seems that we do need to repaint the canvas when hovering, so we set the threshold to Infinity
     * https://echarts.apache.org/en/option.html#hoverLayerThreshold
     */
    hoverLayerThreshold: Infinity
  });

  if (series.length === 0) {
    options = {};
  }
  return options;
}

import { XAXisOption, YAXisOption } from "echarts/types/dist/shared";
import { HoverLegends } from "types";

import { DataEnum } from "models";
import { ChartSeries } from "models/model";

import {
  AXIS_LINE_COLOUR,
  AXIS_SPLIT_NUMBER,
  CHART_DEFAULT_MIN_LOG_VALUE
} from "../../../../constants";
import { ChartLayout } from "../../../../models/chart";
import getLogMinForAxis from "../../../../utils/chart/getLogMinForAxis";

export function getLineSeriesMinMax(series: ChartSeries[]) {
  const seriesData = series || [];
  const allX = seriesData.flatMap((location) => location.x);
  const allY = seriesData.flatMap((location) => location.y);
  const xMinValue = allX.reduce((acc, cur) => (cur < acc ? cur : acc), Infinity);
  const xMaxValue = allX.reduce((acc, cur) => (cur > acc ? cur : acc), -Infinity);
  const yMinValue = allY.reduce((acc, cur) => (cur < acc ? cur : acc), Infinity);
  const yMaxValue = allY.reduce((acc, cur) => (cur > acc ? cur : acc), -Infinity);

  const xMin = { value: xMinValue, type: DataEnum.Number };
  const xMax = { value: xMaxValue, type: DataEnum.Number };
  const yMin = { value: yMinValue, type: DataEnum.Number };
  const yMax = { value: yMaxValue, type: DataEnum.Number };

  return { xMin, xMax, yMin, yMax };
}

export function getStackedBarChartMinMax(series: ChartSeries[]) {
  const seriesData = series || [];
  const columnSum = new Map<string, number>();

  seriesData.forEach((item) => {
    item.x.forEach((xValue, index) => {
      const yValue = item.y[index];
      const key = xValue.toString();

      const currentSum = columnSum.get(key) || 0;
      columnSum.set(key, currentSum + yValue);
    });
  });

  const yMaxValue = Math.max(...Array.from(columnSum.values()));
  const yMinValue = Math.min(...Array.from(columnSum.values()));

  const yMin = { value: yMinValue, type: DataEnum.Number };
  const yMax = { value: yMaxValue, type: DataEnum.Number };
  return { yMin, yMax };
}

export function computeSelectionStates({
  hoverLegendItem,
  hoverLegendGroup,
  attentionWells,
  label,
  groupTitle,
  selectedGroups = []
}: Partial<HoverLegends>): { isSelectedWell: boolean; isHovered: boolean } {
  const hasAttentionWell = attentionWells?.length > 0;
  const selectedWell = hasAttentionWell ? attentionWells[0] : null;

  const isSelectedWell = selectedWell ? label.includes(selectedWell) : false;

  const isGroupHovered =
    groupTitle === hoverLegendGroup ||
    groupTitle === hoverLegendItem ||
    selectedGroups.includes(groupTitle);

  const isHovered =
    selectedWell && hoverLegendItem === selectedWell ? isSelectedWell : isGroupHovered;
  return { isSelectedWell, isHovered };
}

export function computeScatterSelectionStates({
  hoverLegendItem,
  selectedGroups = [],
  label
}: Partial<HoverLegends>): { isHovered: boolean } {
  const isHovered = label === hoverLegendItem || selectedGroups.includes(label);
  return { isHovered };
}

/**
 * Creates default Y-axis options for logarithmic scale charts.
 *
 * @param {ChartLayout} layout - The chart layout configuration.
 * @param {number | undefined} lockedYMin - Optional locked minimum value for Y-axis.
 *                                         If provided, overrides the calculated minimum.
 * @param {number | undefined} lockedYMax - Optional locked maximum value for Y-axis.
 *                                         If provided, sets the maximum bound.
 * @returns {Partial<YAXisOption>} Configuration object for Y-axis with logarithmic scale settings.
 */
export function getDefaultYAxisLogOptions(
  layout: ChartLayout,
  lockedYMin: number | undefined,
  lockedYMax: number | undefined
): Partial<YAXisOption> {
  const yAxis: Partial<YAXisOption> = {
    type: "log"
  };
  let yMinValue =
    lockedYMin === undefined ? getLogMinForAxis(layout) : Number(lockedYMin);

  if (yMinValue < 0.0) {
    yMinValue = CHART_DEFAULT_MIN_LOG_VALUE;
  }

  yAxis.min = yMinValue;

  if (lockedYMax !== undefined) {
    yAxis.max = Number(lockedYMax);
  }
  // Minor gridlines are important when looking at a plot in log scale for accurate data interpretation.
  yAxis.minorSplitLine = {
    show: true,
    lineStyle: { color: AXIS_LINE_COLOUR }
  };
  yAxis.minorTick = {
    splitNumber: AXIS_SPLIT_NUMBER
  };

  return yAxis;
}

/**
 * Creates default X-axis options for logarithmic scale charts.
 *
 * @param {number | undefined} lockedXMin - Optional locked minimum value for X-axis.
 *                                         If provided, overrides the calculated minimum.
 * @param {number | undefined} lockedXMax - Optional locked maximum value for X-axis.
 *                                         If provided, sets the maximum bound.
 * @returns {Partial<XAXisOption>} Configuration object for X-axis with logarithmic scale settings.
 */
export function getDefaultXAxisLogOptions(
  lockedXMin: number | undefined,
  lockedXMax: number | undefined
): Partial<XAXisOption> {
  return {
    type: "log",
    min: lockedXMin ?? 1,
    max: lockedXMax ?? null,
    minorSplitLine: {
      show: true,
      lineStyle: { color: AXIS_LINE_COLOUR }
    },
    minorTick: {
      splitNumber: AXIS_SPLIT_NUMBER
    }
  };
}

import { reactPlugin } from "@datadog/browser-rum-react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { datadogRum } from "@datadog/browser-rum";
import "antd/dist/antd.variable.min.css";
import initStore from "store/store";
import { uid } from "utils";

import { ThemeProvider } from "api/theme.context";

import "./antd.css";
import "./index.css";
import "./main.scss";
import reportWebVitals from "./reportWebVitals";
import "./wdyr";

datadogRum.init({
  applicationId: "de0474d2-9d06-4b9b-aee7-4012fc0606d8",
  clientToken: "pub2092ffaa1cdf6aac8e9c07e78320e9cb",
  site: "us3.datadoghq.com",
  allowedTracingUrls: [
    "https://turinganalytics.dev/app",
    (url) => url.startsWith("https://turinganalytics.dev/app")
  ],
  service: "eva-web",
  env: process.env.REACT_APP_ENV,
  sessionSampleRate: 50,
  sessionReplaySampleRate: 50,
  defaultPrivacyLevel: "mask-user-input",
  plugins: [reactPlugin({ router: true })]
});

const render = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const App = require("./App").default;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let channel = urlParams.get("channel");
  if (!channel) {
    channel = uid();
  }
  const store = initStore({ channel });
  const rootEl = document.getElementById("root");
  if (process.env.REACT_APP_ENV === "dev") {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require("./mocks/browser");
    worker.start();
  }
  // eslint-disable-next-line import/no-named-as-default-member
  ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </React.StrictMode>
    </Provider>,
    rootEl
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);

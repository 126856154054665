import { useEffect, useMemo, useState } from "react";

import {
  ALL_PRODUCT_TYPES,
  ChartTypeLabels,
  CrossKindProduct,
  CrossKindProductList,
  SalesProductList
} from "constants/chart.constants";
import { EvaChart, EvaSeriesKind, TimeStep } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import { DEFAULT_LINE_CHART_OPTIONS } from "entities/charts";
import { ICheckedForecast } from "store/features";
import { TypeWellSeriesData } from "types/charts/typeWellSeries";
import { toExpectedBackendProductName } from "utils/arps/productMapping";

import { useChartEntities } from "hooks/charts";

import { INormalizeBySetting } from "models";
import { EntityKind } from "models/entityKind";

import { useFetchForecastArray } from "components/arps/hooks/useFetchForecastArray";
import { ProductTypeEnum } from "components/arps/models/ArpsSegment";
import { arpsWasm } from "components/arps/utils/UpdateSegment";
import { ArpsSegmentToSegmentDto as arpsSegmentToSegmentDto } from "components/arps/utils/arpsUtils";
import { checkSeriesProductBelongsToSameGroupAsChartProduct } from "components/arps/utils/checkSeriesProductBelongsToSameGroupAsChartProduct";
import { groupArpsSegmentsByProduct } from "components/arps/utils/groupArpsSegmentsByProduct";
import { getTypeWellSeries } from "components/arps/utils/typeWellUtils";

import { getProductType, productStringToProductTypeEnum, uid } from "../../../utils";

export default function useTypeWellSeries(
  checkedGlobalTypeWells: ICheckedForecast[],
  normalizeBySettings: INormalizeBySetting,
  useNormalizeBy: boolean,
  normalizeTypeWell: boolean,
  isTypeWellActive: boolean
) {
  const { id, entityKind } = useChartsContext();
  const {
    chartType,
    product: chartProduct,
    timeStep: timeStepState,
    apiResponse
  } = useChartEntities(id);
  const timeStep = timeStepState?.properties?.value;

  const [typeWellSeries, setTypeWellSeries] = useState([]);

  //type well id only updates when the store value changes
  //this allows us to identify type wells with new data vs no changes
  const typeWellId = useMemo(() => uid(), [checkedGlobalTypeWells]);
  const { data: forecastArrays } = useFetchForecastArray({
    ids: Array.isArray(checkedGlobalTypeWells)
      ? checkedGlobalTypeWells.map((tw) => tw.id)
      : []
  });

  useEffect(() => {
    updateTypeWellSeries();
  }, [
    typeWellId,
    normalizeTypeWell,
    chartType,
    chartProduct,
    timeStep,
    useNormalizeBy,
    // normalizeBySettings.normalizeBy, // TODO chart: not sure why this is needed?
    apiResponse?.layout?.yAxis?.title,
    forecastArrays
  ]);

  function updateTypeWellSeries() {
    const isNonTotalDeclineChart =
      chartType &&
      (chartType.includes("Rate") || chartType.includes("Cum Time")) &&
      !chartType.includes("Total");
    const isCrossKindProduct = CrossKindProductList.includes(chartProduct);
    if (
      !arpsWasm ||
      !checkedGlobalTypeWells ||
      !chartProduct ||
      !isNonTotalDeclineChart ||
      entityKind === EntityKind.Facility ||
      (SalesProductList.includes(chartProduct) &&
        !isCrossKindProduct &&
        chartProduct !== "Sales Gas") // sales gas can be selected as a product to create type wells
    ) {
      setTypeWellSeries([]);
      return;
    }

    const titles = {};
    const temporaryTypeWellSeries: TypeWellSeriesData = [];
    const product = getProductType(chartProduct);

    const crossKindProductKey =
      CrossKindProduct.find((prd) => prd.items.some((item) => item.key === product.key))
        ?.group ?? undefined;

    for (const tw of checkedGlobalTypeWells) {
      const arpsGroupedByProduct = groupArpsSegmentsByProduct(tw.arps);

      let selectedProductSegment = crossKindProductKey
        ? arpsGroupedByProduct[crossKindProductKey.toUpperCase()]
        : arpsGroupedByProduct[product.key.toUpperCase()];
      if (
        selectedProductSegment == null &&
        (product.key === ALL_PRODUCT_TYPES.Oil.key ||
          crossKindProductKey === ALL_PRODUCT_TYPES.Oil.key)
      ) {
        selectedProductSegment =
          "COND." in arpsGroupedByProduct
            ? arpsGroupedByProduct["COND."]
            : arpsGroupedByProduct["CONDENSATE"];
      }

      // Ignore this check if we have a selected production segment

      const hasForecastArray =
        forecastArrays &&
        forecastArrays.forecastArray[tw.id] &&
        forecastArrays.forecastArray[tw.id].length > 0;
      if (!tw.arps?.length && !hasForecastArray) {
        continue;
      }

      // Don't render decline type for ramp-up segment
      const declineSegments = [...tw.arps];

      const dto = arpsSegmentToSegmentDto(arpsWasm, declineSegments);

      if (dto.length == 0 && !hasForecastArray) {
        continue;
      }

      const forecastConstantsWithUnit = (tw.constants ?? [])
        .map((fconst) => {
          const productEnum = productStringToProductTypeEnum(fconst.product);
          //fix the product string so it matches the enum name
          const productString = ProductTypeEnum[productEnum];
          const convertProductStringForBackend =
            toExpectedBackendProductName(productString);
          if (!fconst.unit) {
            return null;
          }
          return {
            ...fconst,
            unit: fconst.unit,
            product: convertProductStringForBackend
          };
        })
        .filter((x) => x != null);

      try {
        const normalize = Object.assign({}, normalizeBySettings, {
          display: normalizeBySettings?.display ?? normalizeBySettings?.displayName,
          normalize: useNormalizeBy,
          normalizeTypeWell,
          // some old Chart widgets will miss those settings by default, so make sure they are set
          // since arpsWasm required these settings, leaving them empty will cause wasm to throw error (and default to default normalization, which is no normalization)
          useMultilinearNormalization:
            normalizeBySettings?.useMultilinearNormalization ?? false,
          threshold: normalizeBySettings?.threshold ?? 0.0,
          lowerScalar: normalizeBySettings?.lowerScalar ?? 0.0,
          higherScalar: normalizeBySettings?.lowerScalar ?? 0.0
        });

        const wellData = normalize.normalize
          ? {
              length: tw.wellData?.HzLength ?? 0,
              stage: tw.wellData?.Stage ?? 0,
              stage_spacing: tw.wellData?.StageSpacing ?? 0,
              proppant: tw.wellData?.Proppant ?? 0,
              proppant_intensity: tw.wellData?.ProppantIntensity ?? 0,
              additional_data: tw.wellData?.AdditionalData ?? []
            }
          : {
              length: 0,
              stage: 0,
              stage_spacing: 0,
              proppant: 0,
              proppant_intensity: 0,
              additional_data: []
            };

        const seriesData = hasForecastArray
          ? arpsWasm.getForecastFromArray(
              forecastArrays.forecastArray[tw.id],
              normalize,
              wellData,
              {}
            )
          : arpsWasm.getForecastFromArpsSegments(
              dto,
              forecastConstantsWithUnit,
              normalize,
              wellData,
              {},
              {
                isDaily: timeStep === TimeStep.Day,
                isCumTime: chartType === EvaChart.CumTime
              }
            );

        for (const series of seriesData) {
          //added indexOf check here because "Total Fluid" key does not match product name
          //from series.product (Total), but Oil and Water should not match for Oil and Water Cut
          const isMismatchedProduct = series.product !== product.key.replace(/\s/g, "");
          const isTotalFluidMatch =
            product.key == ALL_PRODUCT_TYPES.TotalFluid.key &&
            product.key.indexOf(series.product) >= 0;
          const isOnePlusWORMatch =
            series.product == "OnePlusWOR" && product.key == "1+WOR";
          const seriesProductBelongsToSameGroupAsChartProduct =
            isCrossKindProduct &&
            checkSeriesProductBelongsToSameGroupAsChartProduct(series, product);
          if (
            isMismatchedProduct &&
            !isTotalFluidMatch &&
            !isOnePlusWORMatch &&
            !seriesProductBelongsToSameGroupAsChartProduct
          ) {
            continue;
          }
          const yAxisTitle = apiResponse ? apiResponse?.layout?.yAxis?.title : "";
          titles[tw.title] = tw.title;
          temporaryTypeWellSeries.push({
            ...DEFAULT_LINE_CHART_OPTIONS,
            name: tw.title,
            id: `${typeWellId},${tw.id}`,
            originalId: tw.id, // used to identify the type well id in the legend
            type: "line",
            kind: EvaSeriesKind.TypeWell,
            itemStyle: {
              opacity: 1,
              color: tw.color ?? "#000000"
            },
            showSymbol: false,
            lineStyle: { opacity: 1, type: "dashed", width: tw.thickness },
            line: {
              originalColor: tw.color ?? "#000000",
              originalWidth: tw.thickness
            },
            data: getTypeWellSeries(
              chartType as ChartTypeLabels,
              series.rate_cum,
              yAxisTitle,
              timeStep as TimeStep
            )
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return;
      }
    }

    setTypeWellSeries([...temporaryTypeWellSeries]);
  }

  if (isTypeWellActive) {
    return typeWellSeries;
  } else {
    return [];
  }
}

// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import AllInboxIcon from "@material-ui/icons/AllInbox";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { mdiBullseye } from "@mdi/js";
import { Button, Popover, Switch, Tooltip, Typography } from "antd";
import axios from "axios";
import classnames from "classnames";
import { ALL_PRODUCT_TYPES, AXIS_TYPE } from "constants/chart.constants";
import styled from "styled-components";
import { stringToEnum } from "utils";

import { mBinSize } from "models/binSize";
import { EntityKind } from "models/entityKind";

import BinSettings from "components/filter/BinSettings";
import GroupBy, { GroupByValueT } from "components/groupBy";

import { FORECAST_KEY } from "../../../constants";
import { CalculationMethod, EvaChart } from "../../../constants/charts.enums";
import { useChartsContext } from "../../../contexts";
import { useChartEntities } from "../../../hooks/charts";
import { BinType, IGroupBy, QuantileState } from "../../../models";
import { PdenSourceEnum } from "../../../models/pdenDataSourceSetting";
import { updateChartByPath } from "../../../store/features";
import { RootState } from "../../../store/rootReducer";
// import { StackedBarCalculationModel } from "../../chart/components/stacked-bar-options/StackedBarCalculation";
//import { updateIsJitterPlotXBinDefaulting } from "../../chart";
import { visibilityToggleStyles } from "../../shared/SharedStyles";
import { getSyncedSchemaFromProperty, numberToRescatString } from "../../sync/util";
import useAxisDataTypeFilter from "../hooks/useAxisDataTypeFilter";
import useAxisFieldSelection from "../hooks/useAxisFieldSelection";
import useAxisLogToggle from "../hooks/useAxisLogToggle";
import PressureChartOptions from "../toggles/PressureChartOptions";
import StackedBarCalculation from "../toggles/stacked-bar-options/StackedBarCalculation";

const { Text } = Typography;
const dataRoot = process.env.REACT_APP_DATA_RS_ROOT;
function ChartAxisFieldSelector({ type = null, className = "" }) {
  const xAxis = type === AXIS_TYPE.x;
  const yAxis = type === AXIS_TYPE.y;

  const { id, entityKind } = useChartsContext();
  const dispatch = useDispatch();
  const filterId = useSelector((state: RootState) =>
    entityKind == EntityKind.Well ? state.filter.filterId : state.facilityFilter.filterId
  );
  const widgetHoverMap = useSelector((state: RootState) => state.app.widgetHoverMap);
  const globalGroupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);
  const globalFacilityGroupBy = useSelector(
    (state: RootState) => state.groupBy.globalFacilityFocus
  );

  const syncStatus = useSelector((state: RootState) => state.app.syncWells);
  const {
    chartType,
    chartTypeParam: chartTypeParamState,
    pressureChartSettings,
    product,
    dataTable,
    screenshot,
    histogramToggle,
    calculationMethod,
    logAxis
  } = useChartEntities(id);

  const showLogToggle = useAxisLogToggle(type, chartType);
  const showFieldSelection = useAxisFieldSelection(
    type,
    chartType,
    histogramToggle.active
  );
  const axisDataTypeFilter = useAxisDataTypeFilter(type, chartType);

  const isLogScaleActive = logAxis?.properties[type]?.active;
  const paramField = chartTypeParamState.properties[type];
  const isHover = widgetHoverMap?.[id] ?? false;
  const showAxis = !screenshot?.active && !dataTable?.active && isHover;
  const isMosaicChart = chartType === EvaChart.Mosaic;
  const isStackedBarChart = chartType === EvaChart.StackedBar;
  const isTrendDate = chartType === EvaChart.TrendDate;
  const isJitterPlot = chartType === EvaChart.BoxPlot;
  // && chartSettings.showScatter; // TODO chart: Box plot show scatter/jitter plot feature

  const isValidAxesSwap =
    chartTypeParamState.properties.x?.dataType === "Number" ||
    chartTypeParamState.properties.x?.dataType === "Integer";

  const isRateCum = chartType === EvaChart.RateCum;
  const isRateTime = chartType === EvaChart.RateTime;

  const showPressureChartProducts =
    xAxis &&
    (product === ALL_PRODUCT_TYPES.CasingPressure.label ||
      product === ALL_PRODUCT_TYPES.TubingPressure.label) &&
    (isRateCum || (isRateTime && pressureChartSettings?.active));
  const isCalcMethodCount =
    calculationMethod?.properties?.method === CalculationMethod.Count;
  const isHistogramOn = histogramToggle?.active;
  const [syncToFocusField, setSyncToFocusField] = useState(false);
  const [axisBinOpen, setAxisBinOpen] = useState(false);
  const [bin, setBin] = useState<mBinSize>({
    LessThan: null,
    GreaterThan: null,
    MinSize: null,
    BinSize: null,
    BinType: "BinSize"
  });

  const updateForecastSourceToCurrentSchema = () => {
    const chartTypeParamGroupBy = chartTypeParamState.properties[type] as GroupByValueT;
    if (!chartTypeParamGroupBy) {
      return;
    }
    const syncedSchemaFromProperty = getSyncedSchemaFromProperty(chartTypeParamGroupBy);
    if (
      syncStatus &&
      syncStatus.schema !== syncedSchemaFromProperty &&
      syncStatus.isSync &&
      syncStatus.useFcstFromClient &&
      chartTypeParamGroupBy.property.indexOf(FORECAST_KEY) > 0
    ) {
      let newPropertyWithCurrentSchema = chartTypeParamGroupBy.property;
      if (newPropertyWithCurrentSchema.endsWith(FORECAST_KEY)) {
        newPropertyWithCurrentSchema = `${newPropertyWithCurrentSchema}.${
          syncStatus.schema
        }.${numberToRescatString(syncStatus.forecastRescat)}`;
      } else if (syncedSchemaFromProperty) {
        newPropertyWithCurrentSchema = newPropertyWithCurrentSchema.replace(
          syncedSchemaFromProperty,
          syncStatus.schema
        );
      } else {
        return;
      }
      const groupBy = {
        ...chartTypeParamState.properties[type],
        property: newPropertyWithCurrentSchema,
        forecastFolder: syncStatus.schema,
        isForecastToggleOn: true,
        normalizeByDefault: 100
      };
      if (!groupBy.pdenSource) {
        //add pden source if it doesn't exist and set it to Hybrid
        //this is because when switching chart types, the pden source is not set
        groupBy.pdenSource = PdenSourceEnum.Hybrid;
      }
      dispatch(
        updateChartByPath({
          id,
          path: `features.chartTypeParam.properties.${type}`,
          value: groupBy
        })
      );
    }
  };

  useEffect(() => {
    updateForecastSourceToCurrentSchema();
  }, [chartTypeParamState, syncStatus]);

  const updateLog = (isActive: boolean) => {
    if (xAxis) {
      dispatch(
        updateChartByPath({
          id,
          path: "features.logAxis.properties.x.active",
          value: isActive
        })
      );
    }
    if (yAxis) {
      dispatch(
        updateChartByPath({
          id,
          path: "features.logAxis.properties.y.active",
          value: isActive
        })
      );
    }
  };

  const swapAxesParamFields = () => {
    dispatch(
      updateChartByPath({
        id,
        path: "features.chartTypeParam.properties",
        value: {
          x: { ...chartTypeParamState.properties.y },
          y: { ...chartTypeParamState.properties.x }
        }
      })
    );
  };

  const updateParamField = (groupBy: IGroupBy, selectedPdenSource: PdenSourceEnum) => {
    // TODO chart: box plot/show scatter jitter plot x bin defaulting feature ?
    // const isJitterPlotXBinDefaulting =
    //   settingsRef.current.chartType === ALL_CHART_TYPES.BoxPlot.label &&
    //   settingsRef.current.showScatter &&
    //   type === "x";
    //
    // if (isJitterPlotXBinDefaulting) {
    //   updateIsJitterPlotXBinDefaulting(dispatch, isJitterPlotXBinDefaulting);
    // }
    dispatch(
      updateChartByPath({
        id,
        path: `features.chartTypeParam.properties.${type}`,
        value: {
          ...groupBy,
          pdenSource: selectedPdenSource
        }
      })
    );
  };

  useEffect(() => {
    if (
      !xAxis ||
      (axisDataTypeFilter.length > 0 &&
        !axisDataTypeFilter.includes(globalGroupBy.dataType)) ||
      !syncToFocusField
    ) {
      return;
    }
    const gb = entityKind === EntityKind.Well ? globalGroupBy : globalFacilityGroupBy;
    const groupByWithoutBin = { ...gb };

    //cross plot doesn't need bin
    delete groupByWithoutBin.bin;

    const source = stringToEnum(PdenSourceEnum, globalGroupBy.pdenSource);
    let pdenSource = PdenSourceEnum.Public;

    if (source && typeof source === "string") {
      pdenSource = PdenSourceEnum[globalGroupBy.pdenSource];
    } else if (source) {
      pdenSource = source;
    }

    updateParamField(
      groupByWithoutBin,
      //we need the numeric value of the enum
      pdenSource
    );
  }, [globalGroupBy, globalFacilityGroupBy, syncToFocusField, axisDataTypeFilter]);

  const { data: binRequest, refetch } = useQuery(
    "bin-size-axis",
    () => {
      return axios.get(
        `${dataRoot}/api/v1/data/bin-size/${filterId.id}/${btoa(
          encodeURIComponent(paramField.property)
        )}?normalizeField=%27%27&per=0&entityKind=${entityKind}`
      );
    },
    {
      enabled: false
    }
  );

  const updateAxisBin = (binType: BinType, quantile: QuantileState) => {
    const updatedBin = Object.assign({}, bin, {
      BinType: binType,
      Quantile: quantile
    });
    setBin(updatedBin);
    dispatch(
      updateChartByPath({
        id,
        path: "features.chartTypeParam.properties",
        value: {
          x: { ...chartTypeParamState.properties.x, bin: updatedBin },
          y: { ...chartTypeParamState.properties.y }
        }
      })
    );
  };

  function setAxisBin(val: mBinSize) {
    const newBin = Object.assign({}, bin, val);
    setBin(newBin);
  }

  async function resetAxisBin() {
    await refetch();
    const newBin = {
      LessThan: binRequest?.data?.lessThan,
      GreaterThan: binRequest?.data?.greaterThan,
      MinSize: binRequest?.data?.minSize,
      MaxBins: binRequest?.data?.maxBins,
      BinSize: binRequest?.data?.binSize,
      BinType: "BinSize"
    } as mBinSize;
    dispatch(
      updateChartByPath({
        id,
        path: "features.chartTypeParam.properties",
        value: {
          x: { ...chartTypeParamState.properties.x, bin: newBin },
          y: { ...chartTypeParamState.properties.y }
        }
      })
    );
    setBin(newBin);
    setAxisBinOpen(false);
  }

  useEffect(() => {
    if (binRequest?.data?.isValid) {
      const newBin = {
        LessThan: binRequest.data.lessThan,
        GreaterThan: binRequest.data.greaterThan,
        MinSize: binRequest.data.minSize,
        MaxBins: binRequest.data.maxBins,
        MaxBinsSortOrder: binRequest.data.MaxBinsSortOrder,
        BinSize: binRequest.data.binSize,
        BinType: "BinSize"
      } as mBinSize;
      setBin(newBin);
    }
  }, [binRequest]);

  useEffect(() => {
    if (yAxis) return;
    if (paramField.bin) {
      setBin(paramField.bin);
    } else {
      setBin({
        LessThan: null,
        GreaterThan: null,
        MinSize: null,
        BinSize: null,
        BinType: "BinSize"
      });
    }
  }, [paramField.property]);

  const axisInputClassnames = classnames({ xAxis, yAxis });

  const logToggleClassnames = classnames({
    xAxis,
    yAxis,
    visible: showLogToggle && showAxis
  });
  const fieldContainerClassName = classnames(axisInputClassnames, {
    visible: showFieldSelection && showAxis,
    stackUp: isHover
  });
  const wrapperClassnames = classnames(className, {
    xAxis,
    yAxis,
    stackDown: isHover
  });

  async function handleStackedBarCalculationChange(method: CalculationMethod) {
    dispatch(
      updateChartByPath({
        id,
        path: "features.calculationMethod.properties",
        value: {
          method: method
        }
      })
    );
    await refetch();
  }

  return (
    <Wrapper className={wrapperClassnames}>
      <LogToggle className={logToggleClassnames}>
        <span>Log</span>
        <Switch
          size="small"
          checked={isLogScaleActive}
          onChange={(isActive) => updateLog(isActive)}
        />
      </LogToggle>

      {showPressureChartProducts && (
        <ProductSelectContainer>
          <PressureChartOptions />
        </ProductSelectContainer>
      )}

      <FieldContainer className={fieldContainerClassName}>
        {isStackedBarChart && yAxis && (
          <StackedBarCalculation onSelect={handleStackedBarCalculationChange} />
        )}

        <Tooltip title="Swap x and y axis field">
          <AxisBinButton
            size="small"
            icon={<SwapVertIcon />}
            onClick={swapAxesParamFields}
            disabled={!isValidAxesSwap}
          />
        </Tooltip>

        <GroupBy
          value={paramField}
          dataTypeFilters={axisDataTypeFilter}
          chartType={chartType}
          onChange={(gb, _a, _b, _c, source) => updateParamField(gb, source)}
          placement="left"
          entityKind={entityKind}
          isDisabled={yAxis && isStackedBarChart && isCalcMethodCount}
          axisType={type}>
          <StyledButton size="small">
            {(isStackedBarChart || isTrendDate) && yAxis && isCalcMethodCount
              ? "Well Count"
              : paramField.title}
          </StyledButton>
        </GroupBy>
        {xAxis && (
          <Tooltip title="Sync x-axis field to focus field">
            <SyncButton
              size={"small"}
              on={syncToFocusField}
              onClick={() => setSyncToFocusField(!syncToFocusField)}
              icon={<Icon path={mdiBullseye} size={1.0} />}
            />
          </Tooltip>
        )}
        {(isJitterPlot || isMosaicChart || (isStackedBarChart && !isHistogramOn)) &&
          xAxis && (
            <Popover
              trigger="click"
              placement="topRight"
              overlayClassName="chart-bin-settings"
              open={axisBinOpen}
              onOpenChange={async (visible) => {
                if (visible && !paramField.bin) {
                  await refetch();
                } else if (!visible && !paramField.bin && binRequest?.data) {
                  dispatch(
                    updateChartByPath({
                      id,
                      path: "features.chartTypeParam.properties",
                      value: {
                        x: { ...chartTypeParamState.properties.x, bin: bin },
                        y: { ...chartTypeParamState.properties.y }
                      }
                    })
                  );
                }
                setAxisBinOpen(visible);
              }}
              content={
                <AxisBinContainer>
                  <BinsSettingsHeader>
                    <StyledText strong>Bin Settings</StyledText>
                  </BinsSettingsHeader>
                  <BinSettings
                    bin={bin}
                    entityKind={entityKind}
                    canEditBin={paramField.canBin}
                    onBinChange={(newBin) => setAxisBin(newBin)}
                    onBinSettingChange={updateAxisBin}
                    onReset={() => resetAxisBin()}
                    isBinningFromAxis={true}
                  />
                </AxisBinContainer>
              }>
              <AxisBinButton size="small" icon={<AllInboxIcon />} />
            </Popover>
          )}
      </FieldContainer>
    </Wrapper>
  );
}

export default ChartAxisFieldSelector;

const Wrapper = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;

  &.xAxis {
    right: 0;
    height: 24px;
    z-index: 0.5;
  }

  &.yAxis {
    top: 0;
    z-index: 1;
  }

  &.yAxis.stackDown {
    z-index: auto;
  }
`;

const LogToggle = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--color-text);
  font-weight: 500;
  font-size: 12px;
  z-index: 1;

  &.xAxis {
    right: 44px;
    bottom: 4px;
  }

  &.yAxis {
    bottom: -12px;
    transform-origin: left top;
    transform: translateX(4px) rotate(-90deg);
  }
`;

const FieldContainer = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  display: flex;
  align-items: center;

  &.stackUp {
    z-index: 1;
  }

  &.xAxis {
    bottom: 0;
    left: 50%;
    transform: translateX(calc(24px - 50%));
  }

  &.yAxis {
    right: 0;
    top: 50%;
    transform: translate(calc(50% + 14px), -50%) rotate(-90deg);
    transform-origin: center;
  }
`;

const ProductSelectContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 2;

  &.stackUp {
    z-index: 1;
  }

  bottom: 0;
  left: 50%;
  transform: translateX(calc(24px - 50%));
`;

const StyledButton = styled(Button)`
  max-width: 180px;
  width: 180px;

  & > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const AxisBinContainer = styled.div`
  max-width: 36rem;
  background-color: var(--color-text-06);
  display: grid;
  gap: 1px;
`;

const AxisBinButton = styled(Button)`
  color: var(--color-text);
  display: grid;
  justify-content: center;
  align-items: center;
`;
const StyledText = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.25px;
`;

const BinsSettingsHeader = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 var(--space-4);
`;

const SyncButton = styled(AxisBinButton)`
  background-color: ${(props: { on?: boolean }) =>
    props.on ? "var(--color-primary)" : "white"};
  color: ${(props: { on?: boolean }) => (props.on ? "white" : "#a2aaad")};

  &:hover {
    background-color: ${(props: { on?: boolean }) =>
      props.on ? "var(--color-primary)" : "white"};
    color: ${(props: { on?: boolean }) => (props.on ? "white" : "#a2aaad")};
  }

  &:focus {
    background-color: ${(props: { on?: boolean }) =>
      props.on ? "var(--color-primary)" : "white"};
    color: ${(props: { on?: boolean }) => (props.on ? "white" : "#a2aaad")};
  }

  &:active {
    background-color: ${(props: { on?: boolean }) =>
      props.on ? "var(--color-primary)" : "white"};
    color: ${(props: { on?: boolean }) => (props.on ? "white" : "#a2aaad")};
  }
`;

import { TooltipOption } from "echarts/types/dist/shared";
import { EvaChartStates } from "types/factory";

import { getSeriesTooltipDot } from "components/chart/utils";
import { getSeriesNameWithFormattedUwi } from "components/chart/utils/getSeriesNameWithFormattedUWI";

export function translateProbitResponseTooltipOptions(
  states: EvaChartStates
): TooltipOption {
  const { uwis, hoverLegendItem, useProbitLineOfBestFitData, series, uwiCoordinates } =
    states;
  return {
    trigger: "axis",
    confine: true,
    enterable: true,
    extraCssText: "border-style:none;",
    hideDelay: 0,
    show: true,
    axisPointer: {
      label: {
        precision: 2,
        show: true
      },
      type: "cross"
    },
    formatter: (param) => {
      const val = hoverLegendItem;
      if (val) {
        if (val?.includes(" Fit - ")) {
          const separated = val.split(" Fit - ");
          const seriesName = separated[0];
          const contentData = separated[1];
          const color =
            series.find((s) => s.label === seriesName)?.style.hexColor ?? param[0].color;
          return createBestFitTooltip(
            seriesName,
            contentData,
            color,
            useProbitLineOfBestFitData?.active
          );
        } else {
          const uwiData = uwiCoordinates[val];
          const seriesName = uwiData?.group;
          if (!seriesName) {
            return "";
          }
          const data = uwiData.x.toFixed(2);
          const colorList = {};
          for (const s of series) {
            colorList[s.label] = s.style.hexColor;
          }
          const color = seriesName in colorList ? colorList[seriesName] : "";
          const uwi = getSeriesNameWithFormattedUwi(val);
          return createSeriesDataTooltip(uwi, color, seriesName, data);
        }
      } else {
        const series = param[0];

        if (series.seriesName && series.seriesName.includes(" Fit - ")) {
          return null;
        } else {
          const data = series.data.length > 0 ? series.data[0].toFixed(2) : "";
          return createSeriesDataTooltip(
            uwis[series.data[2]],
            series.color !== "none" ? series.color : series.borderColor,
            series.seriesName,
            data
          );
        }
      }
    }
  };
}

function createSeriesDataTooltip(uwi, color, seriesName, data) {
  return `<span style="color:#666";>${getSeriesNameWithFormattedUwi(uwi)}</span>
                <br/>
                <span style="color:#666";>
                ${getSeriesTooltipDot(color)}
                ${seriesName}
                </span>
                <br/>
                <p style="font-family: var(--fontMono);">${data}</p>`;
}

function createBestFitTooltip(
  seriesName,
  contentData,
  color,
  isProbitDataFromLineOfBestFit
) {
  const content = contentData.split(",").map((value) => value.split(":"));

  const header = `<p style="text-align:center;">
                    ${getSeriesTooltipDot(color)} ${seriesName}
                  </p>`;

  const rowContent = (label, data) =>
    `<tr><td style="text-align:left;padding-right:30px;">${label}</td><td style="font-weight:700;color:#666;text-align:right;font-family: var(--fontMono);">${data}</td></tr>`;

  const body = `
                <table style="width:100%;">
                  ${content.map((data) => rowContent(data[0], data[1])).join("")}
                </table>
                    <span style="font-size:10px;">Calculated from ${
                      isProbitDataFromLineOfBestFit ? "line of best fit" : "data"
                    }.</span>`;

  return `${header}${body}`;
}

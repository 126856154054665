import { BarSeriesOption } from "echarts";
import { createStateWithDefaults } from "entities/utils";
import { SeriesType } from "types/echarts";

export const DEFAULT_BAR_CHART_OPTIONS: Partial<BarSeriesOption> = {
  name: "",
  type: SeriesType.Bar,
  blendMode: "source-over",
  blur: {}
};

export const generateBarSeriesOptions = createStateWithDefaults(
  DEFAULT_BAR_CHART_OPTIONS
);

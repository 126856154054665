import { BinSize, MaxBinsSortOrderEnum, mBinSize } from "models/binSize";

import { EvaChart } from "../../constants/charts.enums";
import { IGroupBy } from "../../models";
import { PdenSourceEnum } from "../../models/pdenDataSourceSetting";
import { ChartFocusFeature, ChartTypeParamFeature } from "../../types";

export function sanitizeBinValues(bin: mBinSize): BinSize {
  if (!bin) return;

  const defaultValues = {
    BinSize: 0,
    MinSize: null,
    MaxBins: null,
    GreaterThan: null,
    LessThan: null,
    MaxBinsSortOrder: MaxBinsSortOrderEnum.WellCount,
    BinType: null
  };

  const sanitizedBin = {
    ...defaultValues,
    ...Object.keys(bin).reduce((acc, key) => {
      acc[key] = bin[key] === "" ? defaultValues[key] : bin[key];
      return acc;
    }, {})
  };

  return sanitizedBin;
}

export const getChartFieldInformation = (
  chartType: EvaChart,
  groupBy: IGroupBy,
  chartTypeParam: Partial<ChartTypeParamFeature>,
  chartFocus: Partial<ChartFocusFeature>,
  isHistogramOn: boolean
) => {
  const isHistogram = isHistogramOn && chartType === EvaChart.StackedBar;
  const xField = isHistogram //TODO chart: need to check if it is showHistogram here as well to match old behaviour
    ? {
        type: "x",
        folderName: groupBy.forecastFolder,
        isForecast: groupBy.isForecastToggleOn,
        product: groupBy.product ?? "",
        defaultUnit: groupBy.defaultUnit ?? "",
        title: groupBy.title,
        property: groupBy.property,
        pdenSource:
          typeof groupBy.pdenSource === "number"
            ? groupBy.pdenSource
            : PdenSourceEnum[groupBy.pdenSource]
      }
    : {
        type: "x",
        folderName: chartTypeParam.properties.x.forecastFolder,
        isForecast: chartTypeParam.properties.x.isForecastToggleOn,
        product: chartTypeParam.properties.x.product,
        defaultUnit: chartTypeParam.properties.x.defaultUnit,
        title: chartTypeParam.properties.x.title,
        property: chartTypeParam.properties.x.property,
        pdenSource: chartTypeParam.properties.x.pdenSource
      };

  const yField = {
    type: "y",
    folderName: chartTypeParam.properties.y.forecastFolder,
    isForecast: chartTypeParam.properties.y.isForecastToggleOn,
    product: chartTypeParam.properties.y.product,
    defaultUnit: chartTypeParam.properties.y.defaultUnit,
    title: chartTypeParam.properties.y.title,
    property: chartTypeParam.properties.y.property,
    pdenSource: chartTypeParam.properties.y.pdenSource
  };

  const groupByField = chartFocus?.active ? chartFocus?.properties?.groupBy : groupBy;

  return { xField, yField, groupByField };
};

export const getXVariable = (
  chartType: EvaChart,
  groupBy: IGroupBy,
  chartTypeParam: Partial<ChartTypeParamFeature>,
  isHistogramOn: boolean
) => {
  const isHistogram = isHistogramOn && chartType === EvaChart.StackedBar;
  return isHistogram
    ? {
        valueProperty: groupBy.property,
        pdenSource:
          typeof groupBy.pdenSource === "number"
            ? groupBy.pdenSource
            : PdenSourceEnum[groupBy.pdenSource],
        displayTitle: groupBy.title,
        canBin: groupBy.canBin,
        dataType: groupBy.dataType,
        mappingName: groupBy.property,
        bin: sanitizeBinValues(groupBy.bin) ?? null
      }
    : {
        valueProperty: chartTypeParam.properties.x.property,
        pdenSource: chartTypeParam.properties.x.pdenSource,
        displayTitle: chartTypeParam.properties.x.title,
        canBin: chartTypeParam.properties.x.canBin,
        dataType: chartTypeParam.properties.x.dataType,
        mappingName: chartTypeParam.properties.x.property,
        bin: chartTypeParam.properties.x.bin ?? null
        // TODO chart: there is some specific bin logic for jitter plots that will
        //  need to be captured here. line 2927 in old Chart.tsx
        // bin: isJitterPlot
        //   ? getJitterPlotBin()
        //   : chartTypeParam.properties.x.bin ?? null
      };
};

export const getYVariable = (chartTypeParam: Partial<ChartTypeParamFeature>) => {
  return {
    valueProperty: chartTypeParam.properties.y.property,
    pdenSource: chartTypeParam.properties.y.pdenSource,
    displayTitle: chartTypeParam.properties.y.title,
    canBin: chartTypeParam.properties.y.canBin,
    dataType: chartTypeParam.properties.y.dataType,
    mappingName: chartTypeParam.properties.y.property
  };
};

export const getGroupBySetting = (
  chartFocus: Partial<ChartFocusFeature>,
  groupBy: IGroupBy
) => {
  const chartFocusGroupBy = chartFocus?.properties?.groupBy;
  return chartFocus?.active
    ? {
        ...chartFocusGroupBy,
        bin: {
          ...chartFocusGroupBy.bin,
          ...sanitizeBinValues(chartFocusGroupBy.bin)
        }
      }
    : { ...groupBy, bin: { ...groupBy.bin, ...sanitizeBinValues(groupBy.bin) } };
};

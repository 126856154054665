import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import LockIcon from "@material-ui/icons/Lock";
import classnames from "classnames";
import { useChartsContext } from "contexts";
import { UNDEFINED_LOCK_OPTION } from "entities/charts";
import { updateChartByPath } from "store/features";
import { LockState } from "types/charts/locks";

import { useChartEntities } from "hooks/charts";

import { DataEnum } from "models";

import { AxisInput } from "./AxisInput";
import "./AxisLocker.scss";
import { LockIconWrapper } from "./AxisLocker.styles";

export function YMaxLocker() {
  const { id } = useChartsContext();
  const dispatch = useDispatch();
  const { options: optionString, locks } = useChartEntities(id);

  const [showLock, setShowLock] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [yMax, setYMax] = useState<LockState>(undefined);
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    if (optionString.length === 0) return;
    const options = JSON.parse(optionString);
    const lockOptions = options?.yAxis?.lock ?? UNDEFINED_LOCK_OPTION;
    setShowLock(lockOptions.show);
    setYMax(lockOptions.max);
    const override = options?.yAxis?.lock?.max?.override ?? false;
    if (override && locks.yMax.type !== DataEnum.Reset) {
      dispatch(
        updateChartByPath({
          id,
          path: `locks.yMax`,
          value: { value: options.yAxis.max, type: lockOptions.max.type }
        })
      );
    }
  }, [optionString]);

  useEffect(() => {
    if (locks.yMax) {
      setIsLocked(locks.yMax.value !== undefined);
    }
  }, [locks]);

  const handleLockClick = () => {
    if (isLocked) {
      dispatch(
        updateChartByPath({
          id,
          path: `locks.yMax`,
          value: { value: undefined, type: DataEnum.Reset }
        })
      );
    } else {
      setShowInput(!showInput);
    }
  };

  const axisInputClassnames = classnames({ yAxis: true });

  const lockClassnames = classnames("axis-locker", "flex", {
    isLocked,
    yAxis: true,
    max: true
  });

  return (
    <>
      {showInput ? (
        <AxisInput
          axisInput={yMax}
          dataRange="yMax"
          setShowInput={setShowInput}
          className={classnames(axisInputClassnames, lockClassnames)}
        />
      ) : (
        showLock && (
          <LockIconWrapper className={lockClassnames} onClick={handleLockClick}>
            <LockIcon />
          </LockIconWrapper>
        )
      )}
    </>
  );
}

// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo } from "react";
import { useDispatch } from "react-redux";

import { mdiCalculator } from "@mdi/js";
import { useChartsContext } from "contexts";
import { updateChartByPath } from "store/features";

import { useChartEntities } from "hooks/charts";

import ToolbarButton from "../../base/ToolbarButton";

const ProbitDataSourceToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { useProbitLineOfBestFitData } = useChartEntities(id);
  const isEnabled = useProbitLineOfBestFitData?.enabled;
  const isActive = useProbitLineOfBestFitData?.active;
  const showButton = useProbitLineOfBestFitData?.show;
  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.useProbitLineOfBestFitData.active`,
        value: active
      })
    );
  };
  if (!showButton) return null;

  return (
    <ToolbarButton
      icon={<Icon path={mdiCalculator} size={1.3} />}
      active={isActive}
      data-testId="probitDataSourceToggle"
      overflowLabel="Use line of best fit for stats"
      tooltipText="Use line of best fit for stats"
      onToggle={toggle}
      disabled={!isEnabled}
    />
  );
};

export default memo(ProbitDataSourceToggle);

import { EvaChartStates } from "types/factory";

import { DataEnum } from "models";

export function cagrLocks(states: Partial<EvaChartStates>) {
  const { locks } = states;
  // TODO chart: date locks to be implemented in EVA-3986
  let lockedXMin = locks?.xMin?.value ?? "2000";
  const lockedXMax = locks?.xMax?.value;
  let lockedYMin = locks?.yMin?.value ?? -50;
  let lockedYMax = locks?.yMax?.value ?? 100; // no product locks here as chart specific lock always overrides

  if (locks?.xMin?.type === DataEnum.Reset) lockedXMin = undefined;
  if (locks?.yMin?.type === DataEnum.Reset) lockedYMin = undefined;
  if (locks?.yMax?.type === DataEnum.Reset) lockedYMax = undefined;

  return {
    lockedXMin,
    lockedXMax,
    lockedYMin,
    lockedYMax
  };
}

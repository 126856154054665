export enum EvaChart {
  RateCum = "Rate Cum",
  RateTime = "Rate Time",
  RateDate = "Rate Date",
  TotalRateDate = "Total Rate Date",
  CumTime = "Cum Time",
  CrossPlot = "Cross Plot",
  Probit = "Probit",
  BoxPlot = "Box Plot",
  StackedBar = "Stacked Bar",
  Pie = "Pie",
  Mosaic = "Mosaic",
  MaterialBalanceTime = "Material Balance Time",
  TotalRateCum = "Total Rate Cum",
  CAGR = "CAGR",
  DeclineRate = "Decline Rate",
  TrendDate = "Trend Date",
  WellContribution = "Well Contribution",
  RateDateMidstream = "Rate Date Midstream",
  TotalRateDateMidstream = "Total Rate Date Midstream",
  CrossPlotMidstream = "Cross Plot Midstream",
  None = "None"
}

export enum EvaChartId {
  CrossPlot = 0,
  RateCum = 1
}

export enum TimeStep {
  Day = "day",
  Month = "month",
  Hybrid = "hybrid"
}

export enum EvaSource {
  Public = "Public",
  Private = "Private",
  Hybrid = "Hybrid"
}

export enum EvaSeriesKind {
  Historical = "historical",
  Forecast = "forecast",
  TypeWell = "typewell"
}

export enum EvaStatisticsKind {
  P10 = "p10",
  P50 = "p50",
  P90 = "p90",
  Average = "average"
}

export const enum CalculationMethod {
  Count = 0,
  Sum = 1,
  Average = 2,
  Min = 3,
  Max = 4
}

import { Icon } from "@mdi/react";
import { ReactElement } from "react";

import { Delete, List } from "@material-ui/icons";
import { mdiClock } from "@mdi/js";
import { MenuProps } from "antd";
import styled from "styled-components";

export type MoreOptionsPropsType = {
  product: string;
  isSync: boolean;
  switchToSegmentMode: (product: string) => void;
  setOnTime: (onTime: { open: boolean; product: string }) => void;
  deleteProduct: (product: string) => void;
  kind: string;
  projectPermissions: { canEditTypeWells: boolean };
};

export const ProductMoreOptions = (props: MoreOptionsPropsType): MenuProps["items"] => {
  const options: {
    key: string;
    onClick: () => void;
    label: ReactElement | null;
    danger?: boolean;
    disabled?: boolean;
  }[] = [
    {
      key: "details",
      onClick: () => {
        props.switchToSegmentMode(props.product);
      },
      label: (
        <MoreMenuItem>
          <List /> {"View Segment Detail"}
        </MoreMenuItem>
      )
    }
  ];

  if (props.kind === "Forecasting") {
    options.push({
      key: "Set On Time",
      disabled: !props.projectPermissions.canEditTypeWells,
      onClick: () => {
        props.setOnTime({
          open: true,
          product: props.product
        });
      },
      label: (
        <MoreMenuItem>
          <Icon path={mdiClock} size={1.0} /> {"Set On Time"}
        </MoreMenuItem>
      )
    });
  }

  if (!props.isSync) {
    options.push({
      disabled: !props.projectPermissions.canEditTypeWells,
      key: "delete",
      danger: true,
      onClick: () => props.deleteProduct(props.product),
      label: (
        <MoreMenuItem>
          <Delete /> {"Delete"}
        </MoreMenuItem>
      )
    });
  }
  return options;
};

const MoreMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

import { useEffect, useRef } from "react";

import { EvaChart } from "../../../constants/charts.enums";
import { ChartAxisType } from "../../../models/chart";

const useAxisLogToggle = (axisType: ChartAxisType, chartType: EvaChart): boolean => {
  const showLogToggle = useRef({
    x: false,
    y: false
  });

  useEffect(() => {
    const allowXAxisLogToggle =
      chartType === EvaChart.RateCum || chartType === EvaChart.RateTime;
    const allowYAxisLogToggle =
      chartType === EvaChart.RateCum ||
      chartType === EvaChart.RateTime ||
      chartType === EvaChart.RateDate ||
      chartType === EvaChart.CAGR ||
      chartType === EvaChart.DeclineRate ||
      chartType === EvaChart.TotalRateDate ||
      chartType === EvaChart.CrossPlot ||
      chartType === EvaChart.RateDateMidstream ||
      chartType === EvaChart.TotalRateDateMidstream ||
      chartType === EvaChart.CrossPlotMidstream;

    showLogToggle.current = {
      x: allowXAxisLogToggle,
      y: allowYAxisLogToggle
    };
  }, [chartType]);

  return showLogToggle.current[axisType];
};

export default useAxisLogToggle;

import { IPDB_CONTROL_WELLS_LAYER, IPDB_LAYER } from "constants/mapLayers.constants";

import { STROKE_SHAPEFILE_TYPE } from "../hooks/manage-mapbox-shapefile-loading/utils";

// This function is used to determine the layer before which a new layer should be added
// It is used to ensure that the new layer is added in the correct order
// The function takes two arguments:
// - layers: an array of layers that are currently on the map
// - layer: the new layer that is being added to the map
// The function returns the id of the layer before which the new layer should be added
export function getBeforeLayer(layers, layer) {
  if (layer.id === IPDB_LAYER || layer.id === IPDB_CONTROL_WELLS_LAYER) {
    // If the IPDB layer is being added, try to add it before the first visible shapefile layer

    // Load all available shapefile layers on map
    const allShapefiles = layers
      .filter((l) => l.id.endsWith(STROKE_SHAPEFILE_TYPE))
      .map((l) => l.id.split(STROKE_SHAPEFILE_TYPE)[0]);

    // Get the first visible shapefile layer on map
    const firstVisibleShapefileLayer = layers.find(
      (l) => allShapefiles.includes(l.id) && l.isVisible
    );

    return firstVisibleShapefileLayer?.id ?? null;
  }

  return null;
}

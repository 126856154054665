import { EvaChart } from "constants/charts.enums";
import { TooltipOption } from "echarts/types/dist/shared";
import { EvaChartStates } from "types/factory";

import { translateCAGRResponseTooltipOptions } from "./cagr";
import { translateCrossPlotTooltipOptions } from "./crossPlot";
import { translateCumTimeResponseTooltipOptions } from "./cumTime";
import { translateDeclineRateResponseTooltipOptions } from "./declineRate";
import { translateMosaicResponseTooltipOptions } from "./mosaic";
import { translateProbitResponseTooltipOptions } from "./probit";
import { translateRateCumResponseTooltipOptions } from "./rateCum";
import { translateRateTimeResponseTooltipOptions } from "./rateTime";
import { translateStackedBarResponseTooltipOptions } from "./stackedBar";
import { translateTotalRateCumResponseTooltipOptions } from "./totalRateCum";

export const TOOLTIP_CREATORS_MAP = {
  [EvaChart.RateCum]: translateRateCumResponseTooltipOptions,
  [EvaChart.CrossPlot]: translateCrossPlotTooltipOptions,
  [EvaChart.CumTime]: translateCumTimeResponseTooltipOptions,
  [EvaChart.RateTime]: translateRateTimeResponseTooltipOptions,
  [EvaChart.RateDate]: translateRateTimeResponseTooltipOptions,
  [EvaChart.TotalRateCum]: translateTotalRateCumResponseTooltipOptions,
  [EvaChart.DeclineRate]: translateDeclineRateResponseTooltipOptions,
  [EvaChart.CAGR]: translateCAGRResponseTooltipOptions,
  [EvaChart.Mosaic]: translateMosaicResponseTooltipOptions,
  [EvaChart.StackedBar]: translateStackedBarResponseTooltipOptions,
  [EvaChart.Probit]: translateProbitResponseTooltipOptions,
  [EvaChart.Pie]: () => ({}),

  // midstream charts
  [EvaChart.RateDateMidstream]: translateRateTimeResponseTooltipOptions,
  [EvaChart.CrossPlotMidstream]: translateCrossPlotTooltipOptions
};

export function translateTooltipOptions({
  type,
  states
}: {
  type: EvaChart;
  states: Partial<EvaChartStates>;
}): TooltipOption {
  const tooltipOptions = TOOLTIP_CREATORS_MAP[type];
  if (!tooltipOptions) {
    throw new Error(`Unknown chart type: ${type} to create tooltip options`);
  }
  return tooltipOptions(states);
}

// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import AllInboxIcon from "@material-ui/icons/AllInbox";
import LockIcon from "@material-ui/icons/Lock";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { mdiBullseye } from "@mdi/js";
import { Button, Popover, Switch, Tooltip, Typography } from "antd";
import axios from "axios";
import classnames from "classnames";
import { ALL_CHART_TYPES, AXIS_TYPE } from "constants/chart.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { stringToEnum } from "utils";

import { BinType, IGroupBy, QuantileState } from "models";
import { mBinSize } from "models/binSize";
import { EntityKind } from "models/entityKind";

import BinSettings from "components/filter/BinSettings";
import GroupBy, { GroupByValueT } from "components/groupBy";

import { ALL_PRODUCT_TYPES, FORECAST_KEY } from "../../constants";
import { PdenSourceEnum } from "../../models/pdenDataSourceSetting";
import { visibilityToggleStyles } from "../shared/SharedStyles";
import { getSyncedSchemaFromProperty, numberToRescatString } from "../sync/util";
import AxisInput from "./AxisInput";
import "./ChartAxis.scss";
import PressureChartOptions from "./components/PressureChartOptions";
import StackedBarCalculation, {
  CalculationMethod
} from "./components/stacked-bar-options/StackedBarCalculation";
import {
  toggleAxisInput,
  updateIsJitterPlotXBinDefaulting,
  useChartDispatch,
  useChartState
} from "./context";
import {
  useDataTypeFilter,
  useFieldSelection,
  useLockToggle,
  useLogToggle
} from "./hooks";

const { Text } = Typography;

interface SyncButtonProps {
  $on?: boolean;
}

function ChartAxis({
  className = "",
  axisInput = null,
  axisMinMax,
  isLogScale = false,
  resetAxisLock = null,
  savedAxis = [],
  setAxisInput = null,
  type = null,
  onSaveAxis = null,
  onLogChange
}) {
  const xAxis = type === AXIS_TYPE.x;
  const yAxis = type === AXIS_TYPE.y;

  const dataRoot = process.env.REACT_APP_DATA_RS_ROOT;

  const { axisInputVisible, settings: chartSettings, entityKind } = useChartState();
  const filterId = useSelector((state: RootState) =>
    entityKind == EntityKind.Well ? state.filter.filterId : state.facilityFilter.filterId
  );

  const widgetHoverMap = useSelector((state: RootState) => state.app.widgetHoverMap);
  const isHover = widgetHoverMap?.[chartSettings?.id] ?? false;

  const dispatch = useChartDispatch();
  const showLock = useLockToggle(type, chartSettings);
  const showLogToggle = useLogToggle(type, chartSettings.chartType);
  const showFieldSelection = useFieldSelection(type);
  const axisDataTypeFilter = useDataTypeFilter(type, chartSettings.chartType);
  const globalGroupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);
  const globalFacilityGroupBy = useSelector(
    (state: RootState) => state.groupBy.globalFacilityFocus
  );

  const [paramField, setParamField] = useState(chartSettings.chartTypeParam[type]);
  const [syncToFocusField, setSyncToFocusField] = useState(false);

  const settingsRef = useRef(chartSettings);
  const syncStatus = useSelector((state: RootState) => state.app.syncWells);

  function updateForecastSourceToCurrentSchema() {
    const gb = chartSettings.chartTypeParam[type] as GroupByValueT;
    if (!gb) {
      return;
    }
    const syncedSchemaFromProperty = getSyncedSchemaFromProperty(gb);
    if (
      syncStatus &&
      syncStatus.schema !== syncedSchemaFromProperty &&
      syncStatus.isSync &&
      syncStatus.useFcstFromClient &&
      gb.property.indexOf(FORECAST_KEY) > 0
    ) {
      let newPropertyWithCurrentSchema = gb.property;
      if (newPropertyWithCurrentSchema.endsWith(FORECAST_KEY)) {
        newPropertyWithCurrentSchema = `${newPropertyWithCurrentSchema}.${
          syncStatus.schema
        }.${numberToRescatString(syncStatus.forecastRescat)}`;
      } else if (syncedSchemaFromProperty) {
        newPropertyWithCurrentSchema = newPropertyWithCurrentSchema.replace(
          syncedSchemaFromProperty,
          syncStatus.schema
        );
      } else {
        return;
      }
      const groupBy = {
        ...chartSettings.chartTypeParam[type],
        property: newPropertyWithCurrentSchema,
        forecastFolder: syncStatus.schema,
        isForecastToggleOn: true,
        normalizeByDefault: 100
      };
      if (!groupBy.pdenSource) {
        //add pden source if it doesn't exist and set it to Hybrid
        //this is because when switching chart types, the pden source is not set
        groupBy.pdenSource = PdenSourceEnum.Hybrid;
      }
      setParamField(groupBy);
      dispatch({
        type: "settings",
        payload: {
          ...settingsRef.current,

          chartTypeParam: {
            ...settingsRef.current.chartTypeParam,
            [type]: {
              ...groupBy
            }
          }
        }
      });
    }
  }

  useEffect(() => {
    settingsRef.current = chartSettings;
    updateForecastSourceToCurrentSchema();
  }, [chartSettings, syncStatus]);

  const [axisBinOpen, setAxisBinOpen] = useState(false);
  const [bin, setBin] = useState<mBinSize>({
    LessThan: null,
    GreaterThan: null,
    MinSize: null,
    BinSize: null,
    BinType: "BinSize"
  });

  let inputFormat = "number";
  switch (chartSettings.chartType) {
    case ALL_CHART_TYPES.TotalRateDate.label:
    case ALL_CHART_TYPES.WellContribution.label:
      inputFormat = xAxis ? "YYYY-MM" : "number";
      break;
    case ALL_CHART_TYPES.RateDate.label:
    case ALL_CHART_TYPES.CAGR.label:
    case ALL_CHART_TYPES.BaseDeclineRate.label:
      inputFormat = xAxis ? "YYYY" : "number";
      break;
  }
  const { data: binRequest, refetch } = useQuery(
    "bin-size-axis",
    () => {
      return axios.get(
        `${dataRoot}/api/v1/data/bin-size/${filterId.id}/${btoa(
          encodeURIComponent(paramField.property)
        )}?entityKind=${entityKind}&normalizeField=%27%27&per=0`
      );
    },
    {
      enabled: false
    }
  );

  function updateLog(isLog) {
    if (isLog === isLogScale) return;
    if (xAxis) {
      onLogChange && onLogChange(isLog);
    }
    if (yAxis) {
      onLogChange && onLogChange(isLog);
    }
  }

  const swapAxesParamFields = () => {
    dispatch({
      type: "settings",
      payload: {
        ...chartSettings,
        stackedBarCalculation: settingsRef.current.stackedBarCalculation,
        chartTypeParam: {
          x: {
            ...settingsRef.current.chartTypeParam.y
          },
          y: {
            ...settingsRef.current.chartTypeParam.x
          }
        }
      }
    });
  };

  const isValidAxesSwap =
    settingsRef.current.chartTypeParam.x?.dataType === "Number" ||
    settingsRef.current.chartTypeParam.x?.dataType === "Integer";

  const updateParamField = (groupBy: IGroupBy, selectedPdenSource: PdenSourceEnum) => {
    const isJitterPlotXBinDefaulting =
      settingsRef.current.chartType === ALL_CHART_TYPES.BoxPlot.label &&
      settingsRef.current.showScatter &&
      type === "x";

    if (isJitterPlotXBinDefaulting) {
      updateIsJitterPlotXBinDefaulting(dispatch, isJitterPlotXBinDefaulting);
    }

    dispatch({
      type: "settings",
      payload: {
        ...settingsRef.current,
        stackedBarCalculation: settingsRef.current.stackedBarCalculation,
        chartTypeParam: {
          ...settingsRef.current.chartTypeParam,
          [type]: {
            ...groupBy,
            pdenSource: selectedPdenSource
          }
        }
      }
    });
  };

  useEffect(() => {
    if (
      !xAxis ||
      (axisDataTypeFilter.length > 0 &&
        !axisDataTypeFilter.includes(globalGroupBy.dataType)) ||
      !syncToFocusField
    ) {
      return;
    }
    const gb = entityKind === EntityKind.Well ? globalGroupBy : globalFacilityGroupBy;
    const gbWithoutBin = { ...gb };

    //cross plot doesn't need bin
    delete gbWithoutBin.bin;

    const source = stringToEnum(PdenSourceEnum, globalGroupBy.pdenSource);
    let pdenSource = PdenSourceEnum.Public;

    if (source && typeof source === "string") {
      pdenSource = PdenSourceEnum[globalGroupBy.pdenSource];
    } else if (source) {
      pdenSource = source;
    }

    updateParamField(
      gbWithoutBin,
      //we need the numeric value of the enum
      pdenSource
    );
  }, [globalGroupBy, globalFacilityGroupBy, syncToFocusField, axisDataTypeFilter]);

  function updateAxisBin(binType: BinType, quantile: QuantileState) {
    bin.BinType = binType;
    bin.Quantile = quantile;
    dispatch({
      type: "settings",
      payload: Object.assign({}, chartSettings, {
        chartTypeParam: {
          x: { ...chartSettings.chartTypeParam.x, bin: bin },
          y: chartSettings.chartTypeParam.y
        }
      })
    });
  }

  function setAxisBin(val) {
    const newBin = Object.assign({}, bin, val);
    setBin(newBin);
  }

  async function resetAxisBin() {
    await refetch();
    const newBin = {
      LessThan: binRequest?.data?.lessThan,
      GreaterThan: binRequest?.data?.greaterThan,
      MinSize: binRequest?.data?.minSize,
      MaxBins: binRequest?.data?.maxBins,
      BinSize: binRequest?.data?.binSize,
      BinType: "BinSize"
    } as mBinSize;
    dispatch({
      type: "settings",
      payload: Object.assign({}, chartSettings, {
        chartTypeParam: {
          x: { ...chartSettings.chartTypeParam.x, bin: newBin },
          y: chartSettings.chartTypeParam.y
        }
      })
    });
    setBin(newBin);
    setAxisBinOpen(false);
  }

  useEffect(() => {
    if (binRequest?.data?.isValid) {
      const newBin = {
        LessThan: binRequest.data.lessThan,
        GreaterThan: binRequest.data.greaterThan,
        MinSize: binRequest.data.minSize,
        MaxBins: binRequest.data.maxBins,
        MaxBinsSortOrder: binRequest.data.MaxBinsSortOrder,
        BinSize: binRequest.data.binSize,
        BinType: "BinSize"
      } as mBinSize;
      setBin(newBin);
    }
  }, [binRequest]);

  useEffect(() => {
    setParamField(chartSettings.chartTypeParam[type]);
  }, [chartSettings.chartTypeParam]);

  useEffect(() => {
    if (yAxis) return;
    if (paramField.bin) {
      setBin(paramField.bin);
    } else {
      setBin({
        LessThan: null,
        GreaterThan: null,
        MinSize: null,
        BinSize: null,
        BinType: "BinSize"
      });
    }
  }, [paramField.property]);

  function handleLockClick(index, minMax) {
    if (savedAxis[index]) {
      resetAxisLock(savedAxis[index], axisMinMax.min);
      toggleAxisInput(dispatch, false);
    } else {
      const key = minMax.toLowerCase();

      let lockValue = axisMinMax[key] || "";

      if (lockValue) {
        const isNumber = typeof lockValue === "number";
        lockValue = isNumber ? parseInt(lockValue.toString()) : lockValue;
        lockValue =
          !isNumber && lockValue.length > 7 ? lockValue.substring(0, 4) : lockValue;
      }
      setAxisInput({
        type: `${type}${minMax}`,
        value: lockValue
      });
      toggleAxisInput(dispatch, true);
    }
  }

  const axisInputClassnames = classnames({ xAxis, yAxis });
  const lockClassnames = (index, minMax) =>
    classnames("axis-lock", "flex", {
      isLocked: savedAxis[index],
      xAxis,
      yAxis,
      [minMax]: true,
      stackDown: axisInputVisible
    });
  const logToggleClassnames = classnames({
    xAxis,
    yAxis,
    visible: showLogToggle
  });
  const fieldContainerClassName = classnames(axisInputClassnames, {
    visible: showFieldSelection,
    stackUp: isHover
  });
  const wrapperClassnames = classnames(className, {
    xAxis,
    yAxis,
    stackDown: isHover
  });
  const isMosaicChart = chartSettings.chartType === ALL_CHART_TYPES.Mosaic.label;
  const isStackedBarChart = chartSettings.chartType === ALL_CHART_TYPES.StackedBar.label;
  const isTrendDate = chartSettings.chartType === ALL_CHART_TYPES.TrendDate.label;
  const isJitterPlot =
    chartSettings.chartType === ALL_CHART_TYPES.BoxPlot.label &&
    chartSettings.showScatter;
  const isRateCum = chartSettings.chartType === ALL_CHART_TYPES.RateCum.label;
  const isRateTime = chartSettings.chartType === ALL_CHART_TYPES.RateTime.label;

  const showPressureChartProducts =
    xAxis &&
    (chartSettings.product === ALL_PRODUCT_TYPES.CasingPressure.label ||
      chartSettings.product === ALL_PRODUCT_TYPES.TubingPressure.label) &&
    (isRateCum || (isRateTime && chartSettings.pressureChartSettings.productivityIndex));

  function handleStackedBarCalculationChange(method: CalculationMethod) {
    settingsRef.current.stackedBarCalculation = method;
    dispatch({
      type: "settings",
      payload: {
        ...chartSettings,
        stackedBarCalculation: method
      }
    });
  }

  function handlePressureChartOptionsChange(selectedItem) {
    settingsRef.current.pressureChartSettings.product = selectedItem.key;
    const currentPressureChartSettings = settingsRef.current.pressureChartSettings;
    dispatch({
      type: "settings",
      payload: {
        ...chartSettings,
        pressureChartSettings: {
          ...currentPressureChartSettings,
          product: selectedItem.key
        }
      }
    });
  }

  return (
    <Wrapper className={wrapperClassnames}>
      <LogToggle className={logToggleClassnames}>
        <span>Log</span>
        <Switch size="small" checked={isLogScale} onChange={(v) => updateLog(v)} />
      </LogToggle>

      {showLock && (
        <>
          <LockIconWrapper
            className={lockClassnames(0, "min")}
            onClick={() => handleLockClick(0, "Min")}>
            <LockIcon />
          </LockIconWrapper>

          <LockIconWrapper
            className={lockClassnames(1, "max")}
            onClick={() => handleLockClick(1, "Max")}>
            <LockIcon />
          </LockIconWrapper>

          {axisInput && (
            <AxisInput
              className={axisInputClassnames}
              axisInput={axisInput}
              setAxisInput={setAxisInput}
              onSave={onSaveAxis}
              inputFormat={inputFormat}
            />
          )}
        </>
      )}

      {showPressureChartProducts && (
        <ProductSelectContainer>
          <PressureChartOptions onSelect={handlePressureChartOptionsChange} />
        </ProductSelectContainer>
      )}

      <FieldContainer className={fieldContainerClassName}>
        {(isTrendDate || isStackedBarChart) && yAxis && (
          <StackedBarCalculation onSelect={handleStackedBarCalculationChange} />
        )}

        <Tooltip title="Swap x and y axis field">
          <AxisBinButton
            size="small"
            icon={<SwapVertIcon />}
            onClick={swapAxesParamFields}
            disabled={!isValidAxesSwap}
          />
        </Tooltip>

        <GroupBy
          value={paramField}
          dataTypeFilters={axisDataTypeFilter}
          onChange={(gb, _a, _b, _c, source) => updateParamField(gb, source)}
          placement="left"
          entityKind={entityKind}
          chartType={chartSettings.chartType}
          isDisabled={
            yAxis &&
            isStackedBarChart &&
            chartSettings.stackedBarCalculation == CalculationMethod.Count
          }
          axisType={type}>
          <StyledButton size="small">
            {(isStackedBarChart || isTrendDate) &&
            yAxis &&
            chartSettings.stackedBarCalculation == CalculationMethod.Count
              ? "Well Count"
              : paramField.title}
          </StyledButton>
        </GroupBy>
        {xAxis && (
          <Tooltip title="Sync x-axis field to focus field">
            <SyncButton
              size={"small"}
              $on={syncToFocusField}
              onClick={() => setSyncToFocusField(!syncToFocusField)}
              icon={<Icon path={mdiBullseye} size={1.0} />}
            />
          </Tooltip>
        )}
        {(isJitterPlot ||
          isMosaicChart ||
          isTrendDate ||
          (isStackedBarChart && !chartSettings.showHistogram)) &&
          !isTrendDate &&
          xAxis && (
            <Popover
              trigger="click"
              placement="topRight"
              overlayClassName="chart-bin-settings"
              open={axisBinOpen}
              onOpenChange={async (visible) => {
                if (visible && !paramField.bin) {
                  await refetch();
                } else if (!visible && !paramField.bin && binRequest?.data) {
                  dispatch({
                    type: "settings",
                    payload: {
                      ...chartSettings,
                      chartTypeParam: {
                        x: { ...chartSettings.chartTypeParam.x, bin: bin },
                        y: chartSettings.chartTypeParam.y
                      }
                    }
                  });
                }
                setAxisBinOpen(visible);
              }}
              content={
                <AxisBinContainer>
                  <BinsSettingsHeader>
                    <StyledText strong>Bin Settings</StyledText>
                  </BinsSettingsHeader>
                  <BinSettings
                    bin={bin}
                    entityKind={entityKind}
                    canEditBin={paramField.canBin}
                    onBinChange={(newbin) => setAxisBin(newbin)}
                    onBinSettingChange={updateAxisBin}
                    onReset={() => resetAxisBin()}
                    isBinningFromAxis={true}
                  />
                </AxisBinContainer>
              }>
              <AxisBinButton size="small" icon={<AllInboxIcon />} />
            </Popover>
          )}
      </FieldContainer>
    </Wrapper>
  );
}

export default ChartAxis;

const Wrapper = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;

  &.xAxis {
    right: 0;
    height: 24px;
    z-index: 1;
  }

  &.yAxis {
    top: 0;
    z-index: 1;
  }

  &.yAxis.stackDown {
    z-index: auto;
  }
`;

const LogToggle = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--color-text);
  font-weight: 500;
  font-size: 12px;
  z-index: 1;

  &.xAxis {
    right: 44px;
    bottom: 4px;
  }

  &.yAxis {
    bottom: -12px;
    transform-origin: left top;
    transform: translateX(4px) rotate(-90deg);
  }
`;

const FieldContainer = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  display: flex;
  align-items: center;

  &.stackUp {
    z-index: 1;
  }

  &.xAxis {
    bottom: 0;
    left: 50%;
    transform: translateX(calc(24px - 50%));
  }

  &.yAxis {
    right: 0;
    top: 50%;
    transform: translate(calc(50% + 14px), -50%) rotate(-90deg);
    transform-origin: center;
  }
`;

const LockIconWrapper = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 100vmax;
  isolation: isolate;
  z-index: 3;

  &.stackDown {
    z-index: 0;
  }
`;

const ProductSelectContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;

  &.stackUp {
    z-index: 1;
  }

  bottom: 0;
  left: 50%;
  transform: translateX(calc(24px - 50%));
`;

const StyledButton = styled(Button)`
  max-width: 180px;
  width: 180px;

  & > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const AxisBinContainer = styled.div`
  max-width: 36rem;
  background-color: var(--color-text-06);
  display: grid;
  gap: 1px;
`;

const AxisBinButton = styled(Button)`
  color: var(--color-text);
  display: grid;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.25px;
`;

const BinsSettingsHeader = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 var(--space-4);
`;

const SyncButton = styled(AxisBinButton)<SyncButtonProps>`
  background-color: ${(props: SyncButtonProps) =>
    props.$on ? "var(--color-primary)" : "white"};
  color: ${(props) => (props.$on ? "white" : "#a2aaad")};

  &:hover {
    background-color: ${(props: SyncButtonProps) =>
      props.$on ? "var(--color-primary)" : "white"};
    color: ${(props: SyncButtonProps) => (props.$on ? "white" : "#a2aaad")};
  }

  &:focus {
    background-color: ${(props: SyncButtonProps) =>
      props.$on ? "var(--color-primary)" : "white"};
    color: ${(props: SyncButtonProps) => (props.$on ? "white" : "#a2aaad")};
  }

  &:active {
    background-color: ${(props: SyncButtonProps) =>
      props.$on ? "var(--color-primary)" : "white"};
    color: ${(props: SyncButtonProps) => (props.$on ? "white" : "#a2aaad")};
  }
`;

import { getPrecision, getSeriesTooltipDot, getUnit } from "components/chart/utils";

export function formatSingleSeriesTooltip(series, yAxisTitle: string): string {
  const value = Array.isArray(series.value) ? series.value[1] : series.value;
  const formattedValue =
    value !== "" && yAxisTitle ? value.toFixed(getPrecision(getUnit(yAxisTitle))) : value;

  return `<p style="text-align:right;">${getSeriesTooltipDot(series.color)} ${
    series.seriesName
  }:&emsp;<span style="font-weight:700;color:#666;font-family: var(--fontMono);">${formattedValue}</span></p>`;
}

export function formatMultiSeriesTooltip(items, yAxisTitle: string): string {
  const header = `<table>`;
  const footer = `</table>`;

  const content = items
    .filter((item) => item.value !== "")
    .sort((a, b) =>
      (Array.isArray(a.value) ? a.value[1] : a.value) >
      (Array.isArray(b.value) ? b.value[1] : b.value)
        ? -1
        : 1
    )
    .map((item) => {
      const value = Array.isArray(item.value) ? item.value[1] : item.value;
      if (typeof value !== "number" || isNaN(value)) {
        return "";
      }

      const formattedValue =
        item.seriesName !== "Well Count" && item.seriesName !== "Facility Count"
          ? value.toFixed(getPrecision(getUnit(yAxisTitle)))
          : value;

      return `<tr style="text-align:left;">
          <td>${getSeriesTooltipDot(item.color)}</td>
          <td>${item.seriesName}</td>
          <td><span style="font-weight:700;color:#666;padding-left:10px;float:right;text-align:right;font-family: var(--fontMono);">${formattedValue}</span></td>
        </tr>`;
    })
    .join("");

  return `${header}${content}${footer}`;
}
export function formatBarTooltip(
  series,
  yAxisTitle: string,
  showHistogram: boolean
): string {
  if (showHistogram) {
    // Reformat the tooltip since history only have 1 series per column
    const selectedItem = series.find(
      (item) => item.value !== "" && item.value !== 0 && item.value[0] !== 0
    );

    if (selectedItem) {
      const value = Array.isArray(selectedItem.value)
        ? selectedItem.value[1]
        : selectedItem.value;

      return (
        `<span style="color:#666";>${getSeriesTooltipDot(selectedItem.color)}${
          selectedItem.seriesName
        }</span>` +
        `<p">&emsp;<span style="font-weight:700;color:#666;padding-left:10px;float:right;text-align:right;font-family: var(--fontMono);">${value.toFixed(
          getPrecision(getUnit(yAxisTitle))
        )}</span></p>`
      );
    }

    return "";
  } else {
    const header = `<table>`;
    const footer = `</table>`;

    let content = "";
    let total = 0;
    series
      .filter((item) => item?.data !== "")
      .forEach((item) => {
        const value = Array.isArray(item.value) ? item.value[1] : item.value;
        total += value;
        content += `<tr style="text-align:left;">
              <td>${getSeriesTooltipDot(item.color)}</td>
              <td>${item.seriesName}</td>
              <td><span style="font-weight:700;color:#666;padding-left:10px;float:right;text-align:right;font-family: var(--fontMono);">${value.toFixed(
                getPrecision(getUnit(yAxisTitle))
              )}</span></td>
              </tr>`;
      });
    // The top row showing the group and the total for the stack.
    const headerRow = `<tr>
                  <th colspan="2"><span style="display:block;text-align:left">${
                    series && series.length > 0 ? series[0].axisValueLabel : ""
                  }</span></th>
                  <td><span style="font-weight:700;color:#666;padding-left:10px;float:right;text-align:right;font-family: var(--fontMono);">${total.toFixed(
                    getPrecision(getUnit(yAxisTitle))
                  )}</span></td>
                  </tr>`;
    return `${header}${headerRow}${content}${footer}`;
  }
}

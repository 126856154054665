import styled from "styled-components";

import { formatProductName } from "../../../../../utils/arps/productMapping";
import { CumulativeData } from "../../../../forecasting/Forecasting";
import { PRODUCTS } from "../../../utils/typeWellUtils";

interface ProductLabelParams {
  product: string;
  kind: string;
  productIndex: number;
  segmentIndex: number;
  cumulativeData: CumulativeData;
  mode: "Product" | "Segment";
}

export const GetProductLabel = (props: ProductLabelParams) => {
  const { mode, cumulativeData, product, kind, productIndex, segmentIndex } = props;
  const isTypeWellFirstProductSegment =
    kind === "TypeWell" && productIndex === 0 && segmentIndex === 0;

  const isPrimaryProductForForecasting = (product: string, primaryProduct?: string) => {
    return (
      product === primaryProduct ||
      (product === PRODUCTS.CONDENSATE && primaryProduct === PRODUCTS.OIL)
    );
  };

  const isPrimaryProduct =
    isTypeWellFirstProductSegment ||
    (kind === "Forecasting" &&
      isPrimaryProductForForecasting(product, cumulativeData?.primaryProduct));

  if (mode !== "Product") {
    return <SegmentHeader>Segment {segmentIndex + 1}</SegmentHeader>;
  }

  return (
    <label data-testid="type-well-product-label">
      {isPrimaryProduct && (
        <PrimaryProductIndicator title="Primary Product">P</PrimaryProductIndicator>
      )}{" "}
      {formatProductName(product)}
    </label>
  );
};

const SegmentHeader = styled.div`
  width: 100%;
  text-align: left;
`;

const PrimaryProductIndicator = styled.span`
  font-weight: bold;
  color: teal;
  margin-right: 2px;
`;

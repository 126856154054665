import { EvaChartStates } from "types/factory";

import { DataEnum } from "models";

export function stackedBarLocks(states: Partial<EvaChartStates>) {
  const { locks } = states;
  const lockedXMin = locks?.xMin?.value;
  const lockedXMax = locks?.xMax?.value;
  const lockedYMin = locks?.yMin?.value;

  const lockedYMax =
    locks?.yMax?.type === DataEnum.Reset ? undefined : locks?.yMax?.value;

  return {
    lockedXMin,
    lockedXMax,
    lockedYMin,
    lockedYMax
  };
}

import { IS_DEV_ENV } from "constants/app.constants";
import { CalculationMethod } from "constants/charts.enums";
import { EntityType } from "constants/entities.enum";
import { createEntityState } from "entities/charts/factory";
import { createMergeWithDefaults } from "entities/utils";
import { EvaChartFeatures } from "types";

import { IGroupBy } from "models";

import { PARAM_FIELD } from "../../../../constants";

const STACKED_BAR_FEATURES: Partial<EvaChartFeatures> = {
  screenshot: createEntityState(EntityType.ScreenshotFeature, {
    show: true,
    enabled: true
  }),
  fullScreen: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true
  }),
  datatable: createEntityState(EntityType.ChartDataTableFeature, {
    show: true,
    enabled: true
  }),
  spaghetti: createEntityState(EntityType.ChartsFeature, { show: true }),
  forecast: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true,
    active: true
  }),
  source: createEntityState(EntityType.SourceFeature, { show: true, enabled: true }),
  debug: createEntityState(EntityType.ChartsFeature, {
    show: IS_DEV_ENV,
    enabled: IS_DEV_ENV
  }),
  timeStep: createEntityState(EntityType.TimeStepFeature, {
    show: true,
    enabled: true
  }),
  isProducingRateType: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true
  }),
  cutoff: createEntityState(EntityType.CutOffChartFeature, {
    show: true,
    enabled: true
  }),
  shutInMonths: createEntityState(EntityType.ShutInMonthsChartFeature, {
    show: true,
    enabled: true
  }),
  movingAverageDays: createEntityState(EntityType.MovingAverageDaysChartFeature, {
    show: true,
    enabled: true
  }),
  survivorBias: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true,
    active: true
  }),
  useWeightedRatioAverage: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true,
    active: true
  }),
  lockUnits: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true
  }),
  lasso: createEntityState(EntityType.LassoFeature, {
    show: true,
    enabled: true
  }),
  typewells: createEntityState(EntityType.ChartsFeature, {
    show: true
  }),
  p10: createEntityState(EntityType.P10Feature, {
    show: true
  }),
  p50: createEntityState(EntityType.P50Feature, {
    show: true
  }),
  p90: createEntityState(EntityType.P90Feature, {
    show: true
  }),
  average: createEntityState(EntityType.AverageFeature, {
    show: true
  }),
  normalization: createEntityState(EntityType.NormalizationFeature, {
    show: true
  }),
  chartFocus: createEntityState(EntityType.ChartFocusFeature, {
    show: true,
    enabled: true
  }),
  annotations: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true
  }),
  legend: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true
  }),
  histogramToggle: createEntityState(EntityType.HistogramToggleFeature, {
    show: true,
    enabled: true
  }),
  calculationMethod: createEntityState(EntityType.CalculationMethodFeature, {
    properties: {
      method: CalculationMethod.Count
    }
  }),
  chartTypeParam: createEntityState(EntityType.ChartTypeParamFeature, {
    properties: {
      x: PARAM_FIELD.Vintage_Year as Partial<IGroupBy>,
      y: PARAM_FIELD.HzLength as Partial<IGroupBy>
    }
  })
};

export const stackedBarFeatures = createMergeWithDefaults(STACKED_BAR_FEATURES);

import { ScatterSeriesOption } from "echarts";
import { createStateWithDefaults } from "entities/utils";
import { SeriesType } from "types/echarts";

export const DEFAULT_SCATTER_SERIES_OPTIONS: Partial<ScatterSeriesOption> = {
  type: SeriesType.Scatter,
  data: [],
  id: "",
  name: "",
  animation: false,
  // emphasis: {
  //   focus: "self",
  //   itemStyle: {
  //     opacity: 1,
  //     borderRadius: 2
  //   }
  // },
  // blur: {
  //   itemStyle: {
  //     opacity: 0.2
  //   }
  // },
  blur: {},
  large: true,
  largeThreshold: 1000
};

export const generateScatterSeriesOptions = createStateWithDefaults(
  DEFAULT_SCATTER_SERIES_OPTIONS
);

import { lazy, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import classnames from "classnames";
import {
  AXIS_TYPE,
  DEFAULT_CHART_PRODUCT,
  DEFAULT_MIDSTREAM_CHART_PRODUCT
} from "constants/chart.constants";
import { EvaChart } from "constants/charts.enums";
import { EntityType } from "constants/entities.enum";
import { MIDSTREAM_CHART_BACKGROUND_COLOUR } from "constants/style.constants";
import { ChartStatus, useChartsContext } from "contexts/ChartContext";
import { UNDEFINED_LOCKS } from "entities/charts/chart";
import { createFeatureEntities } from "entities/charts/eva/features";
import { createEntityState } from "entities/charts/factory";
import { createChart } from "store/features/charts/chartSlice";
import styled from "styled-components/macro";
import { disableAxisLocks, noop, retry } from "utils";

import { useChartDependencies, useChartEntities } from "hooks/charts";
import useChartSize from "hooks/charts/echart/useChartSize";

import { EntityKind } from "models/entityKind";

import { ErrorBoundary } from "components/base";
import useTypeWellSeries from "components/charts/hooks/useTypeWellSeries";
import { useUserSettings } from "components/user/hooks";

import { ResultMessageType } from "../../../models/model";
import { RootState } from "../../../store/rootReducer";
import { Indicator } from "../../ui";
import ChartLegend from "../legend/Legend";
import { AxisContainer } from "./AxisContainer/AxisContainer";
import ChartAxisFieldSelector from "./ChartAxisFieldSelector";
import ChartToolbar from "./ChartToolbar";
import "./ChartWrapper.scss";
import Screenshot from "./Screenshot";
import WellCountToggle from "./WellCountToggle";

const Chart = lazy(() => retry(() => import("./Chart")));
interface ChartWrapperT {
  className?: string;
  onFullscreen?: (b: boolean) => void;
}
function ChartWrapper({ className = "", onFullscreen = noop }: ChartWrapperT) {
  const { midstreamChartBackgroundColour, midstreamSettings } = useUserSettings();
  const midstreamEnabled = midstreamSettings?.enabled;
  const { id, entityKind, status } = useChartsContext();
  const wrapperRef = useRef(null);

  const rootClassnames = classnames("chart-wrapper", className);
  const screenshotContainerId = `screenshot-container-${id}`;
  const dispatch = useDispatch();
  const {
    fullScreen,
    screenshot,
    chartLegend,
    bounds,
    dataTable,
    typewells,
    normalizeBy,
    apiResponse
  } = useChartEntities(id);
  const widgetHoverMap = useSelector((state: RootState) => state.app.widgetHoverMap);
  const isHover = widgetHoverMap?.[id] ?? false;

  const { checkedGlobalTypeWells, globalNormalizeBy, useNormalizeBy, normalizeTypeWell } =
    useChartDependencies(entityKind);

  const typeWellSeries = useTypeWellSeries(
    checkedGlobalTypeWells,
    normalizeBy?.active && normalizeBy?.properties
      ? normalizeBy.properties
      : globalNormalizeBy,
    normalizeBy?.active ? true : useNormalizeBy,
    normalizeTypeWell,
    typewells?.active
  );

  const activeEntities = useSelector((state: RootState) => state.app.activeEntityKinds);

  const countExceeded =
    apiResponse?.messageType === ResultMessageType.WellCountExceeded ||
    apiResponse?.messageType === ResultMessageType.SeriesCountExceeded;

  const isMissingData =
    // apiResponse needs to be existed for missing data
    (apiResponse && apiResponse.series && apiResponse.series.length === 0) ||
    (apiResponse &&
      apiResponse.messageType === ResultMessageType.NoData &&
      typeWellSeries.length === 0);

  const isFullscreenActive = fullScreen?.active;
  const isScreenshotActive = screenshot?.active;
  const showChartLegend =
    chartLegend?.active && !screenshot?.active && !dataTable?.active; // hide when screenshot or raw date table is visible

  // custom hooks
  useChartSize(id, wrapperRef);
  useEffect(() => onFullscreen(isFullscreenActive), [isFullscreenActive]);
  // read from session storage
  const sessionData = JSON.parse(
    sessionStorage.getItem(
      `${entityKind === EntityKind.Well ? "chart" : "midstream-chart"}::${id}`
    )
  );

  const chartType =
    sessionData?.chartType ??
    (entityKind === EntityKind.Well ? EvaChart.RateCum : EvaChart.RateDateMidstream);
  const product =
    sessionData?.product ??
    (entityKind === EntityKind.Well
      ? DEFAULT_CHART_PRODUCT
      : DEFAULT_MIDSTREAM_CHART_PRODUCT);
  const locks = sessionData?.locks ?? UNDEFINED_LOCKS;
  const features = createFeatureEntities(chartType, { ...sessionData?.features });
  dispatch(
    createChart({
      id,
      ...createEntityState(EntityType.Chart, {
        id,
        chartType,
        product,
        features,
        locks
      })
    })
  );

  const axisClassnames = classnames({
    visible: isHover && !isScreenshotActive
  });

  return (
    <ErrorBoundary>
      <Wrapper
        ref={wrapperRef} // for useChartSize
        className={rootClassnames}
        data-testid="chartWrapper"
        screenshot={isScreenshotActive}
        useGreyMidstreamChartBackground={
          midstreamChartBackgroundColour?.useGreyBackgroundColour &&
          entityKind === EntityKind.Facility
        }>
        <ChartContainer screenshot={isScreenshotActive}>
          <Chart />
        </ChartContainer>

        {isMissingData && !countExceeded && status === ChartStatus.Idle && (
          <Indicator message={"No Data Available"} />
        )}

        {entityKind === EntityKind.Well && !activeEntities.includes(EntityKind.Well) && (
          <Indicator message={"Wells are hidden"} />
        )}

        {entityKind === EntityKind.Facility &&
          midstreamEnabled &&
          !activeEntities.includes(EntityKind.Facility) && (
            <Indicator message={"Midstream is hidden"} />
          )}

        {!isScreenshotActive && <ChartToolbar />}

        {showChartLegend && bounds?.width > 0 && (
          <ChartLegend
            parentDimensions={{ width: bounds.width, height: bounds.height }}
            id={screenshotContainerId + "chart-legend"}
            screenshot={screenshot}
            bounds={bounds}
            legend={chartLegend}
          />
        )}

        {disableAxisLocks(chartType) && <AxisContainer />}
        <ChartAxisFieldSelector type={AXIS_TYPE.x} className={axisClassnames} />
        <ChartAxisFieldSelector type={AXIS_TYPE.y} className={axisClassnames} />

        <Screenshot containerId={screenshotContainerId} />

        {false && <WellCountToggle type={entityKind} />}

        {/* <ContextMenu
          allow={copyUWIContextMenuAllowed}
          visible={contextMenuVisible}
          position={contextMenuPosition}
          content={<CopyUWIButton uwi={uwiRef.current} />}
        /> */}
      </Wrapper>
    </ErrorBoundary>
  );
}

export default ChartWrapper;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 0;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  border-radius: var(--border-radius);
  background-color: ${(props) =>
    props.screenshot ? "rgba(46, 72, 88, 0.24)" : "inherit"};
  box-shadow: rgba(var(--color-shadow-rgb), 0.2) 0 0 1px;
  padding-top: 10px;
  z-index: 0;
  background: ${(props) =>
    props.useGreyMidstreamChartBackground && !props.screenshot
      ? MIDSTREAM_CHART_BACKGROUND_COLOUR
      : ""};

  &.hovered {
    box-shadow: rgba(var(--color-shadow-rgb), 0.4) 0 0 1px;
  }

  &.fullscreen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--index-fullscreen);
  }
`;

const ChartContainer = styled.div`
  justify-self: ${(props) => (props.screenshot ? "center" : "auto")};

  &:hover {
    z-index: 1;
  }
`;

import { DEFAULT_AXIS_LABEL_FONT_SIZE } from "constants/chart.constants";
import { XAXisOption } from "echarts/types/dist/shared";
import { createStateWithDefaults } from "entities/utils";

export const DEFAULT_X_AXIS_CATEGORY_OPTIONS: Partial<XAXisOption> = {
  type: "category",
  data: [],
  triggerEvent: true,
  name: "",
  id: "xAxis",
  nameLocation: "middle",
  nameGap: 27,
  axisLine: {
    show: true,
    onZero: false,
    lineStyle: { color: "rgb(155,155,155)", width: 1 }
  },
  splitLine: {
    lineStyle: {
      color: "#D9D9D9",
      width: 1,
      type: "solid"
    },
    show: false
  },
  axisTick: {
    show: true,
    lineStyle: {
      width: 1
    }
  },
  axisLabel: {
    fontWeight: 500,
    color: "#5B6770",
    fontSize: DEFAULT_AXIS_LABEL_FONT_SIZE,
    padding: [5, 2, 0, 0],
    interval: 0,
    hideOverlap: true,
    showMinLabel: true,
    showMaxLabel: true
  }
};

export const generateXAxisCategoryOptions = createStateWithDefaults(
  DEFAULT_X_AXIS_CATEGORY_OPTIONS
);

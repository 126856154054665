import { EvaChart } from "constants/charts.enums";
import { createEntityState } from "entities/charts/factory";
import { ChartOption } from "entities/charts/options";
import _zip from "lodash/zip";
import { EvaChartStates } from "types/factory";

import { createChartLocks } from "../locks";
import {
  BLUR_OPACITY,
  DEFAULT_WIDTH,
  DEFAULT_Z_INDEX,
  HIGHLIGHTED_WIDTH,
  HIGHLIGHTED_Z_INDEX,
  SELECTED_WELL_COLOR,
  SELECTED_WELL_Z_INDEX
} from "./constants";
import { updateOptionsWithTypeWellsSeries } from "./updateOptionsWithTypeWellSeries";
import { computeSelectionStates, getLineSeriesMinMax } from "./utils";

export function translateCumTimeResponseToOptions(states: Partial<EvaChartStates>) {
  const {
    title,
    layout,
    series,
    typeWellSeries = [],
    hoverLegendItem,
    hoverLegendGroup,
    attentionWells = [],
    selectedGroups = []
  } = states;
  const isOpaque = !(hoverLegendItem || selectedGroups.length > 0);
  const { xMin, xMax, yMin, yMax } = getLineSeriesMinMax(series);
  const { lockedXMax, lockedXMin, lockedYMax, lockedYMin } = createChartLocks({
    type: EvaChart.CumTime,
    states
  });

  const yAxisTitle = layout?.yAxis?.title || "";

  let options = createEntityState(ChartOption.Chart, {
    chartType: EvaChart.CumTime,
    title: {
      ...createEntityState(ChartOption.Title, {
        text: title.toUpperCase()
      })
    },
    yAxis: {
      ...createEntityState(ChartOption.YAxis, {
        name: yAxisTitle,
        nameGap: 43,
        nameTruncate: { maxWidth: 520.2, ellipsis: "..." },
        min: lockedYMin,
        max: lockedYMax,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        lock: {
          show: true, // it manages if lock should be shown or not
          min: {
            value: Math.floor(yMin.value),
            type: yMin.type
          },
          max: {
            value: Math.floor(yMax.value),
            type: yMax.type,
            override: true // it manages if lock should be overridden or not
          }
        }
      })
    },
    xAxis: {
      ...createEntityState(ChartOption.XAxis, {
        name: layout.xAxis.title,
        nameGap: 27,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        useY: false, // useY is managed at hd chart
        min: lockedXMin,
        max: lockedXMax,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        lock: {
          show: true,
          min: { value: Math.floor(xMin.value), type: xMin.type },
          max: { value: Math.floor(xMax.value), type: xMax.type }
        }
      })
    },
    grid: { top: 46, bottom: 46, left: 62, right: 20 },
    series: series.map((location, index) => {
      const { label, style, x, y, groupTitle } = location;
      const data = _zip(x, y);
      const id =
        groupTitle !== label
          ? `${groupTitle}, ${label}, ${index}`
          : `${groupTitle}, ${index}`;
      const { isSelectedWell, isHovered } = computeSelectionStates({
        hoverLegendItem,
        hoverLegendGroup,
        attentionWells,
        label,
        groupTitle,
        selectedGroups
      });
      const lineSeries = createEntityState(ChartOption.LineSeries, {
        id,
        name: label,
        data: data,
        itemStyle: { color: style?.hexColor ?? "#000" },
        lineStyle: {
          width: isHovered ? HIGHLIGHTED_WIDTH : DEFAULT_WIDTH,
          color: isSelectedWell ? SELECTED_WELL_COLOR : style?.hexColor,
          type: location.isForecast ? "dashed" : "solid",
          opacity: isOpaque ? 1 : isHovered ? 1 : BLUR_OPACITY
        },
        z: isSelectedWell
          ? SELECTED_WELL_Z_INDEX
          : isHovered
          ? HIGHLIGHTED_Z_INDEX
          : DEFAULT_Z_INDEX,
        showSymbol: data.length > 1 ? false : true
      });

      return lineSeries;
    })
  });

  if (typeWellSeries.length > 0) {
    options = updateOptionsWithTypeWellsSeries(options, typeWellSeries);
  }
  if (series.length === 0 && typeWellSeries.length === 0) {
    options = {};
  }
  return options;
}

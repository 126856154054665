import styled from "styled-components";

export const LockIconWrapper = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 100vmax;
  isolation: isolate;
  z-index: 3;
  pointer-events: auto;
`;

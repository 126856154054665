import { EvaChart } from "constants/charts.enums";
import { curry, map } from "lodash";
import { ChartActionType, SeriesType } from "types/echarts";

/**
 * Accepts a chart instance, an action type, and then a series index.
 */
export const dispatchAction = curry((chartInstance, actionType, seriesIndex: number) => {
  chartInstance.dispatchAction({
    type: actionType,
    seriesIndex
  });
});

/**
 * Resets scatter highlights by hiding the target graphic.
 */
export function resetScatterHighlight(chartInstance) {
  chartInstance.setOption({
    graphic: {
      id: "target",
      type: "circle",
      invisible: true
    }
  });
}

/**
 * Updates each series for the line chart.
 */
export function resetLineSeriesHighlights(chartInstance, series) {
  const updatedSeries = map(series, updateLineSeries);
  chartInstance.setOption({ series: updatedSeries });
}
/**
 * Allows highlighting between the focus and map for pie chart
 *
 * @param {Array} seriesArray - The array of series data.
 * @param {string} hoverLegendItem - The name of the hovered legend item.
 * @param {string} hoverLegendGroup - The name of the hovered legend group.
 * @param {Array} selectedGroups - The array of selected groups.
 * @param {Object} chartInstanceRef - The reference to the chart instance.
 */
export function updatePieSeries(
  seriesArray,
  hoverLegendItem,
  hoverLegendGroup,
  selectedGroups,
  chartInstanceRef
) {
  seriesArray[0]?.data?.forEach((item) => {
    if (
      hoverLegendItem === item?.name ||
      hoverLegendGroup === item?.name ||
      selectedGroups.includes(item?.name)
    ) {
      chartInstanceRef.current.dispatchAction({
        type: ChartActionType.Highlight,
        name: item.name
      });
    } else {
      chartInstanceRef.current.dispatchAction({
        type: ChartActionType.Downplay,
        name: item.name
      });
    }
  });
}
/**
 * Updates a single series item for line highlighting.
 */
export function updateLineSeries(seriesItem) {
  return {
    ...seriesItem,
    z: 2,
    emphasis: {
      lineStyle: {
        opacity: 1,
        width: 6,
        color: seriesItem.lineStyle?.color
      }
    }
  };
}

/**
 * Resets highlights based on the chart type.
 * Calls the appropriate highlight reset function depending on the chart type.
 *
 * @param chartInstance - The chart instance.
 * @param series - The series array from the chart options.
 */
export function resetHighlightsByChartType(chartInstance, series) {
  if (series.length === 0) return;
  const seriesType = series[0].type;
  if (seriesType === SeriesType.Scatter) {
    resetScatterHighlight(chartInstance);
  } else if (seriesType === SeriesType.Line) {
    resetLineSeriesHighlights(chartInstance, series);
  }
}

/**
 * Resets all highlights based on the chart type
 */
export function resetHighlights(chartInstance) {
  if (!chartInstance) return;

  const chartOptions = chartInstance.getOption();
  if (!chartOptions?.series) return;

  resetHighlightsByChartType(chartInstance, chartOptions.series);

  dispatchResetActionsForAllSeries(chartInstance, chartOptions.series.length);
}

/**
 * Iterates through all series indices and applies the provided dispatcher function.
 */
export function dispatchForAllSeries(
  dispatcher: (seriesIndex: number) => void,
  seriesCount: number
) {
  for (let seriesIndex = 0; seriesIndex < seriesCount; seriesIndex++) {
    dispatcher(seriesIndex);
  }
}

export function dispatchResetActionsForAllSeries(chartInstance, seriesCount: number) {
  const dispatchDownplay = dispatchAction(chartInstance, ChartActionType.Downplay);
  const dispatchHideTip = dispatchAction(chartInstance, ChartActionType.HideTip);
  dispatchForAllSeries(dispatchDownplay, seriesCount);
  dispatchForAllSeries(dispatchHideTip, seriesCount);
}

/**
 * Updates the emphasis of the target data point and dispatches a highlight action
 *
 * @param {EChartsType} chartInstance - chart instance to update
 * @param {number} seriesIndex - index of the target series
 * @param {number} dataIndex - index of the data point to isolate
 *
 */
export function updateTargetDataEmphasis(chartInstance, seriesIndex, dataIndex) {
  if (!chartInstance) return;

  const chartOptions = chartInstance.getOption();
  if (!chartOptions?.series) return;

  const seriesArray = chartOptions.series;
  const targetSeries = seriesArray[seriesIndex];
  if (targetSeries?.type !== SeriesType.Scatter) return;
  if (!targetSeries?.data) return;
  if (!targetSeries.data[dataIndex]) return;
  const [x, y] = targetSeries.data[dataIndex];

  chartInstance.setOption({
    graphic: {
      id: "target",
      invisible: false,
      type: "circle",
      silent: true,
      position: chartInstance.convertToPixel("grid", [x, y]),
      shape: { cx: 0, cy: 0, r: 5 },
      z: 100
    }
  });
}

/**
 * Updates the emphasis of the target series and dispatches a highlight action
 *
 * @param {EChartsType} chartInstance - chart instance to update
 * @param {number} seriesIndex - indices of the target series
 *
 */
export function updateTargetSeriesEmphasis(chartInstance, seriesIndex) {
  if (!chartInstance) return;

  const chartOptions = chartInstance.getOption();
  if (!chartOptions?.series) return;

  const seriesArray = chartOptions.series;
  // for each series, update the emphasis of the series if its index matches the target seriesIndex array
  const updatedSeries = seriesArray.map((series, idx) => {
    if (seriesIndex.includes(idx)) {
      return {
        ...series,
        z: 4,
        emphasis: {
          lineStyle: {
            opacity: 1,
            width: 6,
            color: "rgb(0,0,0)"
          }
        }
      };
    } else {
      return {
        ...series,
        z: 1,
        emphasis: {
          lineStyle: {
            opacity: 0.8,
            width: 4
          }
        }
      };
    }
  });

  chartOptions.series = updatedSeries;

  chartInstance.setOption(chartOptions);
}

const customChartTypes = new Set<EvaChart>([
  EvaChart.RateCum,
  EvaChart.RateTime,
  EvaChart.TotalRateDate,
  EvaChart.TotalRateCum,
  EvaChart.CAGR,
  EvaChart.DeclineRate,
  EvaChart.CumTime,
  EvaChart.TrendDate,
  EvaChart.RateDate,
  EvaChart.RateDateMidstream,
  EvaChart.TotalRateDateMidstream,
  EvaChart.CrossPlot,
  EvaChart.CrossPlotMidstream,
  EvaChart.Probit
]);

export const isCustomChart = (chartType: EvaChart): boolean => {
  return customChartTypes.has(chartType);
};

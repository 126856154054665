import { EvaChartStates } from "types/factory";

import { DataEnum } from "models";

export function probitLocks(states: Partial<EvaChartStates>) {
  const { locks, product } = states;
  const lockedXMin = locks?.xMin?.value;
  const lockedXMax = locks?.xMax?.value;
  const lockedYMin = locks?.yMin?.value;

  const lockedYMax =
    locks?.yMax?.type === DataEnum.Reset
      ? undefined
      : locks?.yMax?.value ?? (product === "GOR" ? 20000 : undefined);

  return {
    lockedXMin,
    lockedXMax,
    lockedYMin,
    lockedYMax
  };
}

import { LegendStates, MouseSelectionStates, SelectedParams } from "types";
import { SeriesType } from "types/echarts";

import { getSeriesIndex, isValidChartLegendStates } from "./utils";

export function stackedBarHoverLegends({ params }: Partial<MouseSelectionStates>) {
  if (!params?.value?.[0] || typeof params.value[0] !== "string") {
    return {
      hoverLegendItem: params.seriesName,
      hoverLegendGroup: "",
      hoverLegendId: ""
    };
  }

  let seriesName = params.value[0];
  const idx = seriesName.indexOf(" Forecast");
  if (idx > 0) {
    seriesName = seriesName.substring(0, idx).trim();
  }
  return {
    hoverLegendItem: seriesName,
    hoverLegendGroup: "",
    hoverLegendId: ""
  };
}

export function stackedBarSelectionParams({
  states,
  series
}: Partial<LegendStates>): SelectedParams {
  const { hoverLegendGroup, hoverLegendItem, attentionWells } = states;
  isValidChartLegendStates({ states, series });

  const isHoveringOnBins =
    hoverLegendItem && !hoverLegendGroup && attentionWells?.length === 0;

  const seriesIndex = getSeriesIndex({ states, series });

  if (isHoveringOnBins) {
    return {
      seriesIndex: [...seriesIndex],
      seriesType: SeriesType.Bar,
      targetIndex: undefined
    };
  }
}

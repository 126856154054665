import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { translateTooltipOptions } from "entities/charts/eva/tooltips";
import { translateToOptions } from "entities/charts/translators";
import { updateChart } from "store/features";

import { useChartDependencies } from "../useChartDependencies";
import { useChartEntities } from "../useChartEntities";
import { isCustomChart } from "./utils";

export function useCustomChartOptions(
  /*
   * Customized chart are tailored to use `useY` for selection functionality
   */
  chartInstanceRef: React.RefObject<echarts.ECharts>
) {
  const { id, entityKind } = useChartsContext();
  const {
    chartType,
    product,
    apiResponse,
    locks,
    chartTypeParam,
    useProbitLineOfBestFitData,
    pointOpacity,
    pointSize,
    logAxis
  } = useChartEntities(id);
  const dispatch = useDispatch();
  const {
    hoverLegendItem,
    hoverLegendGroup,
    hoverLegendId,
    attentionWells,
    selectedGroups
  } = useChartDependencies(entityKind);

  const prevApiResponse = useRef<string | null>(null);
  const prevPointOpacity = useRef<number | null>(null);
  const prevPointSize = useRef<number | null>(null);
  const prevLocks = useRef<string | null>(null);

  useEffect(() => {
    if (chartInstanceRef.current && apiResponse && isCustomChart(chartType)) {
      if (
        prevApiResponse.current === JSON.stringify(apiResponse) &&
        prevLocks.current === JSON.stringify(prevLocks) &&
        chartInstanceRef.current.getOption() &&
        prevPointOpacity.current === pointOpacity?.properties?.value &&
        prevPointSize.current === pointSize?.properties?.value
      )
        return;

      const options = translateToOptions({
        type: chartType,
        states: {
          ...apiResponse,
          locks,
          product,
          width: chartInstanceRef.current.getWidth(),
          hoverLegendItem,
          hoverLegendGroup,
          hoverLegendId,
          attentionWells,
          selectedGroups,
          chartTypeParam,
          pointOpacity,
          pointSize,
          logAxis
        }
      });
      const tooltipOptions = translateTooltipOptions({
        type: chartType,
        states: {
          ...apiResponse,
          hoverLegendItem,
          hoverLegendGroup,
          hoverLegendId,
          attentionWells,
          selectedGroups,
          useProbitLineOfBestFitData
        }
      });
      if (prevApiResponse.current !== JSON.stringify(apiResponse)) {
        chartInstanceRef.current.setOption({ ...options, tooltip: tooltipOptions }, true);
        chartInstanceRef.current.resize();
      } else {
        chartInstanceRef.current.setOption({ ...options, tooltip: tooltipOptions });
      }
      chartInstanceRef.current.resize();

      dispatch(
        updateChart({
          id,
          options: JSON.stringify(options)
        })
      );
    }
    prevApiResponse.current = JSON.stringify(apiResponse);
    prevLocks.current = JSON.stringify(locks);
    prevPointOpacity.current = pointOpacity?.properties?.value;
    prevPointSize.current = pointSize?.properties?.value;
  }, [
    chartInstanceRef.current,
    apiResponse,
    locks,
    product,
    hoverLegendItem,
    hoverLegendGroup,
    hoverLegendId,
    attentionWells,
    selectedGroups,
    useProbitLineOfBestFitData,
    pointOpacity,
    pointSize
  ]);
}

import { DatePicker, Select } from "antd";
import { utc } from "moment";
import styled from "styled-components";

import { ArpInputField } from ".";
import { ArpInputParams } from "../../models/UserArpsModel";
import { SegmentTemplate } from "../arps/models/SegmentTemplate";
import { getHeaderUnits } from "../arps/utils/arpsUtils";
import { getAbbreviatedSegmentLabel } from "../arps/utils/typeWellUtils";

const ArpInput = (params: ArpInputParams) => {
  const {
    header,
    product,
    mode,
    segmentData,
    isCalculated,
    isForecastConstant,
    isRatio,
    isSync,
    handleReload,
    handleReset,
    handleSave,
    onChange,
    typeWellTemplates,
    productSegmentTemplateName,
    fieldExists,
    inputRef,
    onBlur,
    isDisabled,
    kind
  } = params;

  try {
    const key = header + "-" + product;
    let val: number = segmentData && header in segmentData ? segmentData[header] : null;
    if (isForecastConstant && segmentData.value) {
      val = segmentData.value;
    }
    if (
      (isForecastConstant && header !== "Qi") ||
      (!fieldExists && !isForecastConstant)
    ) {
      return <FillerDiv key={key} />;
    }

    if (header === "Template") {
      return (
        <Select
          key={key}
          value={productSegmentTemplateName}
          placeholder="Template"
          onChange={(val) => {
            onChange && onChange(val);
          }}
          options={typeWellTemplates.map((item: SegmentTemplate) => ({
            label: getAbbreviatedSegmentLabel(item.name),
            value: item.name
          }))}
        />
      );
    }

    if (header === "Start Date" || header === "End Date") {
      const date = val ? utc(segmentData[header]) : null;
      return (
        <DatePicker
          allowClear={false}
          value={date}
          disabled={
            isDisabled || mode === "Segment" || (isRatio && !isForecastConstant) || isSync
          }
          key={key}
          onChange={(evt) => onChange && onChange(evt?.toDate())}
        />
      );
    }

    return (
      <ArpInputField
        header={header}
        globalRef={inputRef}
        key={key}
        min={header.startsWith("Q") || header.startsWith("t") ? 0 : undefined} // Q = Flow rate, initial, final. t = times for ramp-up or constrained templates.
        type="number"
        isCalculated={isCalculated}
        unit={getHeaderUnits(header, product)}
        disabled={
          isDisabled ||
          mode === "Segment" ||
          header === "CTD" ||
          (isRatio && !isForecastConstant) ||
          isSync ||
          // "EUR" in forecasting widget is "Remaining"
          (header === "EUR" && kind === "Forecasting")
        }
        step={header.startsWith("B") ? 0.1 : 1}
        value={Number(val)}
        handleReload={handleReload}
        handleReset={handleReset}
        handleSave={handleSave}
        onBlur={onBlur}
        onChange={(value) => {
          if (isNaN(value)) {
            value = 0;
          }
          onChange && onChange(value);
        }}
      />
    );
  } catch (err) {
    return <div key={header + "-" + product}>Error {err}</div>;
  }
};
const FillerDiv = styled.div`
  min-height: 27px;
`;

export default ArpInput;

import { EvaChartStates } from "types/factory";

import { DataEnum } from "models";

import { checkSeriesDataYValueGreaterThanMax } from "./utils/checkSeriesDataYValueGreaterThanMax";
import { getYMaxProductLock } from "./utils/getYMaxProductLock";
import { getYMinProductLock } from "./utils/getYMinProductLock";

export function rateTimeLocks(states: Partial<EvaChartStates>) {
  const { locks, product, series } = states;
  const lockedXMin = locks?.xMin?.value;
  const lockedXMax = locks?.xMax?.value;
  const lockedYMin =
    locks?.yMin?.type === DataEnum.Reset
      ? undefined
      : locks?.yMin?.value ?? getYMinProductLock(product);

  const lockedYMax =
    locks?.yMax?.type === DataEnum.Reset
      ? undefined
      : checkSeriesDataYValueGreaterThanMax(
          locks?.yMax?.value ?? getYMaxProductLock(product),
          series,
          product
        );

  return {
    lockedXMin,
    lockedXMax,
    lockedYMin,
    lockedYMax
  };
}

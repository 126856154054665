import { memo } from "react";
import { useDispatch } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";

import { useChartEntities } from "hooks/charts";

import { Histogram } from "components/icons";

import ToolbarButton from "../../base/ToolbarButton";

const HistogramToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { histogramToggle } = useChartEntities(id);
  const isEnabled = histogramToggle?.enabled;
  const isActive = histogramToggle?.active;
  const showButton = histogramToggle?.show;

  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: "features.histogramToggle.active",
        value: active
      })
    );
  };

  if (!showButton) {
    return null;
  }

  return (
    <ToolbarButton
      icon={<Histogram />}
      active={isActive}
      data-testid="histogram-toggle"
      overflowLabel="Histogram"
      tooltipText="Toggle Histogram"
      onToggle={toggle}
      disabled={!isEnabled}
    />
  );
};

export default memo(HistogramToggle);

import { EvaChart } from "constants/charts.enums";
import { createEntityState } from "entities/charts/factory";
import { ChartOption } from "entities/charts/options";
import { EvaChartStates } from "types/factory";

import { createChartLocks } from "../locks";
import { DEFAULT_Z_INDEX, HIGHLIGHTED_Z_INDEX, SELECTED_WELL_Z_INDEX } from "./constants";
import { updateOptionsWithTypeWellsSeries } from "./updateOptionsWithTypeWellSeries";
import { computeSelectionStates, getStackedBarChartMinMax } from "./utils";

export function translateStackedBarResponseToOptions(states: Partial<EvaChartStates>) {
  const {
    title,
    layout,
    series,
    typeWellSeries = [],
    hoverLegendItem,
    hoverLegendGroup,
    attentionWells
  } = states;
  const yAxisTitle = layout?.yAxis?.title || "";
  const { yMin, yMax } = getStackedBarChartMinMax(series);

  const { lockedYMax, lockedYMin } = createChartLocks({
    type: EvaChart.StackedBar,
    states
  });

  let options = createEntityState(ChartOption.Chart, {
    chartType: EvaChart.StackedBar,
    title: {
      ...createEntityState(ChartOption.Title, {
        text: title.toUpperCase()
      })
    },
    yAxis: {
      ...createEntityState(ChartOption.YAxis, {
        name: yAxisTitle,
        nameGap: 43,
        nameTruncate: { maxWidth: 520.2, ellipsis: "..." },
        min: lockedYMin,
        max: lockedYMax,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        lock: {
          show: true, // it manages if lock should be shown or not
          min: {
            value: Math.floor(yMin.value),
            type: yMin.type
          },
          max: {
            value: Math.floor(yMax.value),
            type: yMax.type,
            override: true // it manages if lock should be overridden or not
          }
        }
      })
    },
    xAxis: {
      ...createEntityState(ChartOption.XAxisCategory, {
        name: layout.xAxis.title,
        data: layout.xAxis.data,
        nameGap: 27,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        useY: false // useY is managed at hd chart
      })
    },
    grid: { top: 46, bottom: 46, left: 62, right: 20 },
    series: series.map((location, index) => {
      const { label, style, y, groupTitle } = location;

      const data = y;
      const id =
        groupTitle !== label
          ? `${groupTitle}, ${label}, ${index}`
          : `${groupTitle}, ${index}`;
      const { isSelectedWell, isHovered } = computeSelectionStates({
        hoverLegendItem,
        hoverLegendGroup,
        attentionWells,
        label,
        groupTitle
      });
      const barSeries = createEntityState(ChartOption.Bar, {
        id,
        name: label,
        data: data,
        itemStyle: { color: style?.hexColor ?? "#000" },
        stack: "stackedBar",
        z: isSelectedWell
          ? SELECTED_WELL_Z_INDEX
          : isHovered
          ? HIGHLIGHTED_Z_INDEX
          : DEFAULT_Z_INDEX
      });

      return barSeries;
    })
  });
  if (typeWellSeries.length > 0) {
    options = updateOptionsWithTypeWellsSeries(options, typeWellSeries);
  }
  if (series.length === 0 && typeWellSeries.length === 0) {
    options = {};
  }
  return options;
}

import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";

import {
  ALL_PRODUCT_TYPES,
  BLACK_HEX,
  DEFAULT_LINE_THICKNESS,
  EMPTY_CHART_RESULT
} from "constants/chart.constants";
import { Generators, createEntityState } from "entities/charts/factory";
import { updateChart } from "store/features/charts/chartSlice";
import { ChartRequestType } from "types";
import { getProductType, uid } from "utils";

import { usePostChartData } from "api/charts/usePostChartData";

import { EntityKind } from "models/entityKind";
import { ResultMessageType } from "models/model";

import handleMissingChartDataToasts from "../../components/charts/utils/handleMissingChartDataToasts";
import handleSyncWarningToast from "../../components/charts/utils/handleSyncWarningToast";
import { EMPTY_FILTER_ID } from "../../constants/app.constants";
import {
  getChartFieldInformation,
  getGroupBySetting,
  getXVariable,
  getYVariable
} from "./proxies";
import { useChartDependencies } from "./useChartDependencies";
import { useChartEntities } from "./useChartEntities";

export interface ChartDataControllerProps {
  id: string;
  entityKind: EntityKind;
}

/**
 * Hook to manage chart data fetching based on current chart states and dependencies
 */
export function useChartDataController({ id, entityKind }: ChartDataControllerProps) {
  const {
    filterId,
    globalNormalizeBy,
    useNormalizeBy,
    activeColorPalette,
    groupBy,
    lockedColors,
    syncWells
  } = useChartDependencies(entityKind);
  const dispatch = useDispatch();

  const {
    chartType,
    product,
    sum: spaghettiState,
    forecast,
    source: sourceState,
    timeStep: timeStepState,
    isProducingRateType: isProducingRateTypeState,
    cutoff,
    shutInMonths,
    movingAverageDays,
    survivorBias,
    useWeightedRatioAverage,
    lockUnits,
    average,
    p10,
    p50,
    p90,
    normalizeBy,
    chartFocus,
    annotations,
    chartTypeParam,
    histogramToggle,
    calculationMethod,
    pressureChartSettings,
    overrideSizeLimit,
    logAxis,
    useProbitLineOfBestFitData,
    useLineOfBestFit
  } = useChartEntities(id);

  const getColorLockedItems = () => {
    const lockedAndPreferredColors = { ...lockedColors };
    chartFocus?.properties?.colorPalette?.preferredColors?.forEach((preferredColor) => {
      lockedAndPreferredColors[`${preferredColor.property}:${preferredColor.value}`] = {
        color: preferredColor.color,
        property: preferredColor.property,
        value: preferredColor.value
      };
    });
    return chartFocus?.active ? lockedAndPreferredColors : lockedColors;
  };
  const stackedBarCalculationMethod = calculationMethod?.properties?.method;
  const source = sourceState?.properties?.value;
  const timeStep = timeStepState?.properties?.value;
  const showLinesOfBestFit = useLineOfBestFit?.active;
  const sum = spaghettiState?.active;
  const isProducingRateType = isProducingRateTypeState?.active;
  const isForecastActive = forecast?.active;
  const isHistogramOn = histogramToggle?.active;
  const isPressureChart =
    product === ALL_PRODUCT_TYPES.CasingPressure.label ||
    product === ALL_PRODUCT_TYPES.TubingPressure.label;

  const request = useMemo(() => {
    if (!chartType) return null;
    const chartRequest = createEntityState(chartType as Generators, {
      requestId: uid(),
      FilterId: filterId.id,
      entityKind,
      NormalizeBy: globalNormalizeBy,
      UseNormalizeBy: useNormalizeBy,
      ReverseColor: chartFocus?.active
        ? chartFocus?.properties?.colorPalette.reverse
        : activeColorPalette.reverse,
      ColorPalette: chartFocus?.active
        ? chartFocus?.properties?.colorPalette
        : activeColorPalette,
      chartFieldInformation: getChartFieldInformation(
        chartType,
        groupBy,
        chartTypeParam,
        chartFocus,
        isHistogramOn
      ),
      syncClientFcst: syncWells && syncWells.isSync && syncWells.useFcstFromClient,
      syncForecastRescat: syncWells && syncWells.forecastRescat,
      syncClientPden: syncWells && syncWells.isSync && syncWells.usePdenFromClient,
      syncClientSchema: syncWells?.schema,
      chartSetting: {
        product: getProductType(product)?.key, // Need to convert display name to key, ie "Oil + Cond" to "Oil"
        showIndividualSeries: sum,
        showForecast: isForecastActive,
        source,
        timeStep,
        pdenDataSourceSetting: {
          source,
          timeStep
        },
        rateType: isProducingRateType ? 1 : 0,
        cutoff,
        shutInMonths,
        movingAverageDays,
        survivorBias,
        useWeightedRatioAverage,
        lockUnits,
        normalizeBy: normalizeBy.properties,
        showAverage: average.active,
        stackedBarCalculation: stackedBarCalculationMethod,
        averageSettings: {
          color: average.properties?.color || BLACK_HEX,
          thickness: average.properties?.thickness || DEFAULT_LINE_THICKNESS
        },
        showP10: p10.active,
        p10Settings: {
          color: p10.properties?.color || BLACK_HEX,
          thickness: p10.properties?.thickness || DEFAULT_LINE_THICKNESS
        },
        showP50: p50.active,
        p50Settings: {
          color: p50.properties?.color || BLACK_HEX,
          thickness: p50.properties?.thickness || DEFAULT_LINE_THICKNESS
        },
        showP90: p90.active,
        p90Settings: {
          color: p90.properties?.color || BLACK_HEX,
          thickness: p90.properties?.thickness || DEFAULT_LINE_THICKNESS
        },
        annotationSettings: {
          showAnnotations: annotations.active
        },
        xVariable: getXVariable(chartType, groupBy, chartTypeParam, isHistogramOn),
        yVariable: getYVariable(chartTypeParam),
        pressureChartSettings: {
          product: pressureChartSettings?.properties.product,
          productivityIndex: isPressureChart ? pressureChartSettings?.active : false
        },
        showLinesOfBestFit: showLinesOfBestFit
      },
      GroupBy: getGroupBySetting(chartFocus, groupBy),
      overrideSizeLimit,
      useProbitDataFromLineOfBestFit: useProbitLineOfBestFitData.active
    }) as ChartRequestType;
    // colorLockedItems have unique keys that createMergeWithDefaults will not identify, so needs to be manually set
    chartRequest.colorLockedItems = getColorLockedItems();
    return chartRequest;
  }, [
    chartType,
    product,
    filterId.lastUpdated,
    globalNormalizeBy,
    useNormalizeBy,
    activeColorPalette,
    forecast,
    source,
    sum,
    timeStep,
    isProducingRateType,
    cutoff,
    shutInMonths,
    movingAverageDays,
    survivorBias,
    useWeightedRatioAverage,
    lockUnits,
    groupBy,
    lockedColors,
    overrideSizeLimit,
    average,
    p10,
    p50,
    p90,
    normalizeBy,
    chartFocus,
    annotations,
    chartTypeParam,
    syncWells,
    histogramToggle,
    stackedBarCalculationMethod,
    pressureChartSettings,
    logAxis,
    useProbitLineOfBestFitData,
    showLinesOfBestFit
  ]);

  const { data: apiResponse, loading, error } = usePostChartData(request);
  const prevMessageTypeRef = useRef<ResultMessageType | null>(null);

  useEffect(() => {
    dispatch(
      updateChart({
        id,
        overrideSizeLimit: false
      })
    );
  }, [filterId]);

  useEffect(() => {
    if (!filterId || filterId.id === EMPTY_FILTER_ID) {
      dispatch(
        updateChart({
          id,
          chartType,
          product,
          request,
          response: EMPTY_CHART_RESULT
        })
      );
      return;
    }
    if (
      apiResponse &&
      (apiResponse.messageType === ResultMessageType.Ok ||
        apiResponse.messageType === ResultMessageType.NoData ||
        apiResponse.messageType === ResultMessageType.MissingNormalizeData)
    ) {
      handleSyncWarningToast(apiResponse, chartType, id);
      if (
        apiResponse.messageType === ResultMessageType.MissingNormalizeData &&
        prevMessageTypeRef.current !== ResultMessageType.MissingNormalizeData
      ) {
        handleMissingChartDataToasts(apiResponse, id);
      }
      prevMessageTypeRef.current = apiResponse.messageType;

      dispatch(
        updateChart({
          id,
          chartType: chartType,
          product,
          request,
          response: apiResponse
        })
      );
    }
  }, [apiResponse]);

  return { loading, error, apiResponse };
}

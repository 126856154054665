import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// import {
//   DEFAULT_AXIS_FONT_SIZE,
//   DEFAULT_AXIS_LABEL_FONT_SIZE,
//   DEFAULT_LEGEND_FONT_SIZE,
//   DEFAULT_TITLE_FONT_SIZE
// } from "constants/chart.constants";
// import { THEME_FONT_FAMILY } from "constants/style.constants";
import { useChartsContext } from "contexts/ChartContext";
import { translateTooltipOptions } from "entities/charts/eva/tooltips";
import { translateToOptions } from "entities/charts/translators";
import { selectChartHistogramToggleFeature, updateChart } from "store/features";
import { RootState } from "store/rootReducer";

import { useChartEntities } from "../useChartEntities";
import { isCustomChart } from "./utils";

// import { applySpacingToChartOptions } from "utils";

export function useChartOptions(chartInstanceRef: React.RefObject<echarts.ECharts>) {
  const { id } = useChartsContext();
  const {
    chartType,
    pointOpacity,
    pointSize,
    chartTypeParam,
    product,
    apiResponse,
    locks,
    logAxis,
    useProbitLineOfBestFitData
  } = useChartEntities(id);
  const dispatch = useDispatch();
  const isHistogramOn = useSelector((state: RootState) =>
    selectChartHistogramToggleFeature(state, id)
  );

  const prevApiResponse = useRef<string | null>(null);
  const prevPointOpacity = useRef<number | null>(null);
  const prevPointSize = useRef<number | null>(null);
  const prevLocks = useRef<string | null>(null);

  useEffect(() => {
    if (chartInstanceRef.current && apiResponse && !isCustomChart(chartType)) {
      if (
        // TODO chart: adjust logic based on active widget
        // This is to prevent the option from resetting during highlighting and lassoing
        prevApiResponse.current === JSON.stringify(apiResponse) &&
        prevLocks.current === JSON.stringify(prevLocks) &&
        chartInstanceRef.current.getOption() &&
        prevPointOpacity.current === pointOpacity?.properties?.value &&
        prevPointSize.current === pointSize?.properties?.value
      )
        return;

      const options = translateToOptions({
        type: chartType,
        states: {
          ...apiResponse,
          locks,
          product,
          pointOpacity,
          pointSize,
          width: chartInstanceRef.current.getWidth(),
          chartTypeParam,
          logAxis
        }
      });
      const tooltipOptions = translateTooltipOptions({
        type: chartType,
        states: {
          ...apiResponse,
          useProbitLineOfBestFitData,
          histogramToggle: isHistogramOn
        }
      });
      // TODO chart: adjust chart spacing based on current option
      // const spacedOptions = applySpacingToChartOptions(
      //   options,
      //   chartInstanceRef?.current,
      //   {
      //     titleFontSize: DEFAULT_TITLE_FONT_SIZE,
      //     axisFontSize: DEFAULT_AXIS_FONT_SIZE,
      //     axisLabelFontSize: DEFAULT_AXIS_LABEL_FONT_SIZE,
      //     legendFontSize: DEFAULT_LEGEND_FONT_SIZE,
      //     fontFamily: THEME_FONT_FAMILY
      //   },
      //   true
      // );

      chartInstanceRef.current.setOption({ ...options, tooltip: tooltipOptions }, true); // true to replace previous options
      chartInstanceRef.current.resize();

      dispatch(
        updateChart({
          id,
          options: JSON.stringify(options)
        })
      );
    }
    prevApiResponse.current = JSON.stringify(apiResponse);
    prevPointOpacity.current = pointOpacity?.properties?.value;
    prevPointSize.current = pointSize?.properties?.value;
    prevLocks.current = JSON.stringify(locks);
  }, [
    chartInstanceRef.current,
    apiResponse,
    locks,
    product,
    pointOpacity,
    pointSize,
    isHistogramOn,
    useProbitLineOfBestFitData
  ]);
}

import { ALL_PRODUCT_TYPES } from "constants/chart.constants";

export const getYMaxProductLock = (product: string) => {
  const productLocks = {
    [ALL_PRODUCT_TYPES.GOR.label]: 20000,
    [ALL_PRODUCT_TYPES.CGR.label]: 1000,
    [ALL_PRODUCT_TYPES.ISOR.label]: 10,
    [ALL_PRODUCT_TYPES.CSOR.label]: 10,
    [ALL_PRODUCT_TYPES.Shrinkage.label]: 100,
    [ALL_PRODUCT_TYPES.CWB.label]: 100,
    [ALL_PRODUCT_TYPES.IWB.label]: 100
  };

  return productLocks[product] ?? undefined;
};

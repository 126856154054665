import { memo } from "react";
import { useDispatch } from "react-redux";

import InfoIcon from "@material-ui/icons/Info";
import { Divider, InputNumber, Space, Switch, Typography } from "antd";
import { TimeStep } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";
import styled from "styled-components/macro";
import { EvaChartFeatures } from "types";

import { useChartEntities } from "hooks/charts";

import { Tooltip } from "components/base";

import { ALL_PRODUCT_TYPES } from "../../../../constants";

const { Text } = Typography;

const SettingsOptions = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const {
    cutoff,
    shutInMonths,
    movingAverageDays,
    survivorBias,
    useWeightedRatioAverage,
    lockUnits,
    timeStep: timeStepState,
    pointSize: pointSizeState,
    pointOpacity: pointOpacityState,
    pressureChartSettings,
    product,
    useLineOfBestFit
  } = useChartEntities(id);
  const showPointSettings = pointSizeState?.show && pointOpacityState?.show;
  const pointSize = pointSizeState?.properties?.value;
  const pointOpacity = pointOpacityState?.properties?.value;
  const timeStep = timeStepState?.properties?.value;
  const isProductivityIndexChecked = pressureChartSettings?.active;
  const isPressureChart =
    product === ALL_PRODUCT_TYPES.CasingPressure.label ||
    product === ALL_PRODUCT_TYPES.TubingPressure.label;
  const lineOfBestFitEnabled = useLineOfBestFit?.active;

  const handleNumericChange = (key: keyof EvaChartFeatures) => (v) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.${key}.properties.value`,
        value: v
      })
    );
  };

  const handleToggle = (key: keyof EvaChartFeatures) => (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.${key}.active`,
        value: active
      })
    );
  };

  const updateProductivityIndex = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: "features.pressureChartSettings.active",
        value: active
      })
    );
  };

  return (
    <StyledSpace direction="vertical" split={<StyledDivider />}>
      <Container>
        <Text>Cut Off (%)</Text>
        <Tooltip title="Calculate average until well count below cutoff percent">
          <StyledIcon />
        </Tooltip>
        <StyledInput
          type="number"
          value={cutoff}
          min={0}
          max={100}
          controls={false}
          data-testid="cutOffInput"
          onChange={(val: string | null | number) => {
            if (val === null || val === "") {
              return;
            }
            handleNumericChange("cutoff")(val);
          }}
        />
      </Container>
      <Container>
        <Text>Shut In Months</Text>
        <Tooltip title="Number of non producing months to be considered shut-in. Shut-in months are removed from historical production.">
          <StyledIcon />
        </Tooltip>
        <StyledInput
          type="number"
          value={shutInMonths}
          min={0}
          max={20}
          controls={false}
          data-testid="shutInMonthsInput"
          onChange={(val: string | null | number) => {
            if (val === null || val === "") {
              return;
            }
            handleNumericChange("shutInMonths")(val);
          }}
        />
      </Container>

      <Container>
        <Text>Moving Average Days</Text>
        <Tooltip title="The number of days to use to calculate the simple moving average.">
          <StyledIcon />
        </Tooltip>
        <StyledInput
          type="number"
          value={movingAverageDays}
          min={0}
          max={timeStep === TimeStep.Day ? 100 : 365}
          controls={false}
          data-testid="movingAverageDayInput"
          onChange={(val: string | null | number) => {
            if (val === null || val === "") {
              return;
            }
            handleNumericChange("movingAverageDays")(val);
          }}
        />
      </Container>

      {showPointSettings && (
        <>
          <Container>
            <Text>Point Size</Text>
            <Tooltip title="Size of dots">
              <StyledIcon />
            </Tooltip>
            <StyledInput
              value={pointSize}
              min={0}
              max={100}
              controls={false}
              data-testid="pointSizeInput"
              onChange={(val: string | null | number) => {
                if (val === null || val === "") {
                  return;
                }
                handleNumericChange("pointSize")(val);
              }}
            />
          </Container>
          <Container>
            <Text>Point Opacity</Text>
            <Tooltip title="Adjust the focused opacity">
              <StyledIcon />
            </Tooltip>
            <StyledInput
              value={pointOpacity}
              min={0}
              max={1}
              step={0.1}
              controls={false}
              data-testid="pointOpacityInput"
              onChange={(val: string | null | number) => {
                if (val === null || val === "") {
                  return;
                }
                handleNumericChange("pointOpacity")(val);
              }}
            />
          </Container>
        </>
      )}
      <Container>
        <Text>Prevent Survivor Bias</Text>
        <Tooltip title="Allow wells that complete early to continue to affect average">
          <StyledIcon />
        </Tooltip>
        <Switch
          data-testid="survivorBiasCB"
          size="small"
          checked={survivorBias}
          onChange={handleToggle("survivorBias")}
        />
      </Container>

      <Container>
        <Text>Weighted Ratio</Text>
        <Tooltip title="Cumulative numerator/cumulative denominator">
          <StyledIcon />
        </Tooltip>
        <Switch
          data-testid="weightedRatioCB"
          size="small"
          checked={useWeightedRatioAverage}
          onChange={handleToggle("useWeightedRatioAverage")}
        />
      </Container>

      <Container>
        <Text>Lock Rate Units</Text>
        <Tooltip title="Locking rate units will keep the units on the y-axis constant, rather than switching between bbl/d and Mbbl/d, for example">
          <StyledIcon />
        </Tooltip>
        <Switch
          data-testid="lockUnits"
          size="small"
          checked={lockUnits}
          onChange={handleToggle("lockUnits")}
        />
      </Container>

      {useLineOfBestFit?.show && (
        <Container>
          <Text>Show Lines of Best Fit</Text>
          <RightSwitch
            data-testid="linesOfBestFit"
            size="small"
            checked={lineOfBestFitEnabled}
            onChange={handleToggle("useLineOfBestFit")}
          />
        </Container>
      )}

      {isPressureChart && (
        <Container>
          <Text>Productivity Index</Text>
          <Tooltip title="Plot the productivity index instead of the selected pressure.">
            <StyledIcon />
          </Tooltip>
          <Switch
            data-testId="productivityIndexSwitch"
            size="small"
            checked={isProductivityIndexChecked}
            onChange={updateProductivityIndex}
          />
        </Container>
      )}
    </StyledSpace>
  );
};

export default memo(SettingsOptions);

const StyledSpace = styled(Space)`
  --tooltip: var(--color-text);
  width: 260px;
  display: flex;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledIcon = styled(InfoIcon)`
  color: #a2aaad;
  margin-right: auto;
`;

const StyledInput = styled(InputNumber)`
  width: 70px;
  border-radius: var(--border-radius);
`;

const RightSwitch = styled(Switch)`
  margin-left: auto;
`;

import { PRODUCT_Y_MAX } from "constants/chart.constants";

import { ChartSeries } from "models/model";

export const checkSeriesDataYValueGreaterThanMax = (
  max: number,
  series: ChartSeries[],
  product: string
) => {
  if (!max) {
    return undefined;
  }
  // for certain products, the max value is always returned regardless of
  // series data y max
  if (PRODUCT_Y_MAX.includes(product)) {
    return max;
  }
  return series.some((data) => {
    return data.y.some((value) => value > max);
  })
    ? max
    : undefined;
};

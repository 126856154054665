import { memo } from "react";
import { useDispatch } from "react-redux";

import { Button } from "antd";
import { EvaChart } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import { difference } from "lodash";
import {
  addMultipleLegendItemsToFilter,
  filterWellsFromList,
  updateChartByPath
} from "store/features";
import {
  addMultipleLegendItemsToFilter as addMultipleLegendItemsToFacilitiesFilter,
  filterWellsFromList as filterFacilitiesFromList
} from "store/features/filter/facilityFilterSlice";
import styled from "styled-components";

import { useChartDependencies, useChartEntities } from "hooks/charts";

import { EntityKind } from "models/entityKind";

import { FilterMinus } from "components/icons";
import FilterPlusIcon from "components/icons/FilterPlus";

const AddToFilterButton = () => {
  const { id, entityKind } = useChartsContext();
  const dispatch = useDispatch();
  const { lassoBrushList, chartType, apiResponse } = useChartEntities(id);
  const { groupBy } = useChartDependencies(entityKind);
  const property = groupBy?.property;
  function inactiveLasso() {
    dispatch(
      updateChartByPath({
        id,
        path: `features.lasso.active`,
        value: false
      })
    );
  }

  const handleAddToFilter = () => {
    const list = [...lassoBrushList];
    if (
      chartType === EvaChart.CrossPlot ||
      chartType === EvaChart.CrossPlotMidstream ||
      chartType === EvaChart.Probit
    ) {
      dispatch(
        entityKind === EntityKind.Facility
          ? filterFacilitiesFromList(list)
          : filterWellsFromList(list)
      );
    } else {
      dispatch(
        entityKind === EntityKind.Facility
          ? addMultipleLegendItemsToFacilitiesFilter(list, false, property)
          : addMultipleLegendItemsToFilter(list, false, property)
      );
    }

    inactiveLasso();
  };

  const handleExcludeFromFilter = () => {
    if (
      chartType === EvaChart.CrossPlot ||
      chartType === EvaChart.CrossPlotMidstream ||
      chartType === EvaChart.Probit
    ) {
      const filteredList = difference(apiResponse.uwis, lassoBrushList);
      const list = [...filteredList];
      dispatch(
        entityKind === EntityKind.Facility
          ? filterFacilitiesFromList(list)
          : filterWellsFromList(list)
      );
    } else {
      dispatch(
        entityKind === EntityKind.Facility
          ? addMultipleLegendItemsToFacilitiesFilter(lassoBrushList, true, property)
          : addMultipleLegendItemsToFilter(lassoBrushList, true, property)
      );
    }
    inactiveLasso();
  };

  return (
    <>
      <ButtonContainer>
        <StyledButton type="text" onClick={handleAddToFilter}>
          <FilterPlusIcon />
          Add to filter
        </StyledButton>
        <StyledButton type="text" onClick={handleExcludeFromFilter}>
          <FilterMinus />
          Exclude from filter
        </StyledButton>
      </ButtonContainer>
    </>
  );
};

export default memo(AddToFilterButton);

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

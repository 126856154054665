import { useCallback, useRef, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useDispatch } from "react-redux";

import LockIcon from "@material-ui/icons/Lock";
import classnames from "classnames";
import { useChartsContext } from "contexts";
import { updateChartByPath } from "store/features";
import styled from "styled-components/macro";
import { LockState } from "types/charts/locks";

import { DataEnum } from "models";

import "./AxisInput.scss";

export function AxisInput({
  className = "",
  disabled = false,
  axisInput = { value: "0", type: DataEnum.Number } as LockState,
  dataRange = "",
  inputFormat = "number",
  type = "Number",
  step = "",
  min = "",
  max = "",
  placeholder = "0",
  setShowInput = null,
  ...rest
}) {
  const dateInputRef = useRef(null);
  const [hoveringLock, setHoveringLock] = useState(false);
  const { id } = useChartsContext();
  const dispatch = useDispatch();
  const [currValue, setCurrValue] = useState(
    inputFormat === "number" && typeof axisInput.value === "string"
      ? Number(axisInput.value.replace(/,/g, ""))
      : inputFormat === "number" && typeof axisInput.value === "number"
      ? axisInput.value
      : inputFormat === "YYYY-MM"
      ? axisInput.value.toString()
      : axisInput.value.toString().substr(0, 4)
  );

  function handleHide() {
    setShowInput(false);
  }

  function handleBlur(e) {
    if (e.currentTarget === e.target) {
      !hoveringLock && handleHide();
    }
  }

  function handleChange(evt) {
    setCurrValue(evt.target.value);
  }

  function handleSave() {
    dispatch(
      updateChartByPath({
        id,
        path: `locks.${dataRange}`,
        value: {
          value: typeof currValue === "string" ? Number.parseFloat(currValue) : currValue,
          type: axisInput.type
        }
      })
    );

    setShowInput(false);
  }

  function handleKeypress(event) {
    if (event.key === "Enter") {
      handleSave();
    }
    if (event.key === "Escape") {
      handleHide();
    }
  }

  function handleDatetimeChange(value) {
    const formattedValue = typeof value === "object" ? value.format(inputFormat) : value;
    setCurrValue(formattedValue);
  }

  function handelDatetimeClose(value) {
    const newValue = value?.format ? value.format(inputFormat) : value;
    setCurrValue(() => newValue);
  }

  const innerRef = useCallback((input) => {
    if (input !== null) {
      input.select();
    }
  }, []);

  const wrapperClassnames = classnames(
    "axis-input",
    className,
    inputFormat,
    axisInput?.type
  );

  return (
    <Wrapper className={wrapperClassnames}>
      <LockIcon
        onClick={handleSave}
        onMouseEnter={() => setHoveringLock(true)}
        onMouseLeave={() => setHoveringLock(false)}
      />
      {inputFormat === "number" ? (
        <input
          className="input"
          ref={innerRef}
          tabIndex={disabled ? -1 : 0}
          value={currValue}
          placeholder={placeholder}
          type={type}
          step={step}
          id={id}
          min={min}
          max={max}
          onBlur={handleBlur}
          onKeyDown={handleKeypress}
          onChange={handleChange}
          {...rest}
        />
      ) : (
        <Datetime
          inputProps={{
            autoFocus: true,
            className: "input",
            ref: dateInputRef,
            onKeyDown: handleKeypress
          }}
          dateFormat={inputFormat}
          timeFormat={false}
          value={currValue as string | Date}
          onChange={handleDatetimeChange}
          onClose={handelDatetimeClose}
          closeOnSelect
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 3px;
  width: 90px;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px 0 rgba(var(--color-text-rgb), 10%);
  background-color: var(--white);
  z-index: 1;
  pointer-events: auto;
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  pointer-events: none;

  &.xAxis {
    right: 0;
    height: 24px;
    z-index: 5;
  }

  &.yAxis {
    top: 0;
    z-index: 1;
  }
`;
